@mixin type-post-7 {
    --font-size-title: var(--font-size-xlarge);

    .mask svg path {
        fill: var(--green);
    }

    .categories, 
    .time {
        color: var(--dark-green);
        bottom: var(--padding-xxs);
    }
    
    .time {
        right: var(--padding-xxs);
    }

    .categories {
        left: var(--padding-xxs);
    }

    .title {
        color: var(--light-green);
        top: var(--padding-xxs);
    }
}

.new.--type-post-7 {
    @include type-post-7;
}
