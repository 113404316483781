%language-selector {
    --border: 0;
    --height: 1.875rem; // rem(30px);
    --height-open: calc(var(--height) * (var(--n-options) + 0));
    --arrow-open: calc(var(--height-open) - var(--height));
    --border-radius: calc(var(--height) * .5);
    --time-show:.2s;
    --time-hide:.2s;
    --padding: 0;
  
    --bg: transparent;
    --bg-hover: var(--primary-bg);
    --color:currentColor;
    --border-color:var(--secondary);
  
    --opacity: 0;
    --rotation: -25deg;
  
    @include basic-a();
    position: relative;
  
    line-height: 1;
    text-transform: uppercase;
  
    color: var(--color);
    cursor: pointer;
  
    padding: 0 2em 0 0;

    transform-style: preserve-3d;
    perspective: 200px; 
      
    .value {
      position: relative;
    }
  
    /////ARROW
    &:after {
        @include pseudo-element-absolute();
        content: '↓';
        display: block;
        top: calc(50% - .5em);
        right: .9em;
        transition: transform var(--time-hide) var(--ease-out-quad), opacity var(--time-hide) var(--ease-out-quad);
    }
  
  
    /////LISTADO
    > ul {
      position: absolute;
      list-style: none;
      margin: 0;
      padding: 2.25em 0 .75em;
      border-radius: .8em;
      top:-1em;
      left:-.8em;
      width: 100%;
      pointer-events: none;
      background-color: var(--bg-hover);
      
      opacity: var(--opacity);
      transform-style: preserve-3d; 
      transform-origin: 0 0 0; 
      transform: rotateX(var(--rotation));

      transition:
        transform .3s var(--ease-in-quad),
        opacity .2s var(--ease-in-quad);
  
      li {  
        display: block;
        &:empty {
          display: none;
        }
      }
  
      a {
        display: block;
        position: relative;
        @include basic-a();
        padding: .25em .8em;

        border-radius: var(--border-radius);
        color: var(--color);
        
        &:focus,
        &:hover {
            &:after {
                @include pseudo-element-absolute();
                content: '←';
                display: block;
                top: calc(50% - .5em);
                right: .3em;
            }
        }
  
        &.is-active {
          display: none;
          
        }
      }
    }

  
    &.--show,
    &:hover {
      color: var(--primary-color);
      --opacity: 1;
      --rotation: 0;

      //ARROW
      &:after {
        opacity: 0;
        transform: translateY(var(--arrow-open));
        transition: transform var(--time-show) var(--ease-out-quad), opacity .2s var(--ease-out-quad);
      }

      ul {
        display: block;
        pointer-events: all;       

        transition: transform var(--time-show) var(--ease-out-quad), opacity .2s var(--ease-out-quad);
      }
    }
  }
  
  .language-selector {
    @extend %language-selector;
  }
  
  
#Sidemenu > .content > nav > .lang-selector.sidemenu__link {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    a {
        @include basic-a();
        color: currentColor;
        padding: 0;
        margin-left: var(--padding-xs);

        &.is-active {
            text-decoration: underline;
            pointer-events: none;
        }
    }
}