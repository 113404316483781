
.form-vendor form {
    --font-size: var(--font-size-base);
    --input-height: #{rem(40)};
    --button-height: #{rem(54px)};

    [type="submit"] {
        --padding: #{rem(8px)}; 
        --width: #{rem(140px)};
        --bg: var(--light-green);
        --fill: var(--light-green);
        --color: var(--dark-green);
        --border: var(--light-green);
        --font-size: var(--font-size-xxsmall);
        --bg-hover: var(--green);
        --border-hover: var(--green);
        --color-hover: var(--white);
    }

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
    }
}

%fieldsetStuff {
    border: 1px solid var(--green);
    border-radius: rem(81);
    height: var(--input-height);
    position: relative;
    display: flex;
    padding: 0;

    span {
        color: var(--green) !important;
        height: var(--input-height) !important;
        width: var(--input-height) !important;
        
        &::before {
            line-height: var(--input-height);
            transform-origin: center center;
            text-align: center;
            color: var(--green);
        }
    }
}

%inputStuff {
    height: var(--input-height) !important;
    line-height: var(--input-height) !important;
    flex-grow: 1;
    text-transform: uppercase;
    padding: 0 var(--padding-s);
    margin: 0;
    width: 100% !important;
    color: var(--green) !important;
    font-size: var(--font-size) !important;
}

%buttonStuff {
    --padding: #{rem(8px)}; 
    --width: #{rem(140px)};
    --bg: var(--light-green);
    --fill: var(--light-green);
    --color: var(--dark-green);
    --border: var(--light-green);
    --font-size: var(--font-size-xxsmall);
    --bg-hover: var(--green);
    --border-hover: var(--green);
    --color-hover: var(--white);

    @include basic-a();
    border: 1px solid var(--border) !important;
    border-radius: var(--button-height);
    background-color: var(--bg);
    color: var(--color);
    height: var(--button-height);
    font-size: var(--font-size);
    min-width: var(--width);
    position: relative;
    padding: var(--padding);
    text-transform: uppercase;
    width: var(--width);
    box-sizing: border-box;

    @include isCursor() {
        transition: .2s ease-out;

        &:hover {
            border: 1px solid var(--border-hover);
            background-color: var(--bg-hover);
            color: var(--color-hover) !important;
        }
    }
}

.form-vendor form {
    font-size: var(--font-size) !important;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    > div {
        width: 100%;
    }

    button[type="submit"] {
        @extend %buttonStuff;
    }

    input,
    label,
    textarea,
    select,
    fieldset,
    b {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        width: auto;
        -webkit-appearance: none;
    }

    .mi-be-chain-select,
    .mi-be-promo-field,
    .mi-be-multiroom-select,
    .mi-be-datepicker-select,
    .mi-be-occupation-select {
        padding: 0;
        margin: 0;
        background-color: transparent;

        &:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
    }

    .DateRangePickerInput {
        @extend %fieldsetStuff;
        display: flex;
        margin-right: 0;

        > * {
            margin-right: 0;
        }

        .DateRangePickerInput_calendarIcon {
            display: none;
        }

        .DateInput {
            flex-grow: 1; 
            width: auto;
        }

        .DateInput_input {
            @extend %inputStuff;
            margin: 0;

            &.DateInput_input__focused {
                background-color: var(--green15);
                border-radius: rem(81);
            }
        }

        .DateRangePickerInput {
            font-size: var(--font-size) !important; 
        }

        .DateRangePickerInput_arrow {
            width: 15%;
            text-align: center;

            svg {
                height: 100% !important;
                fill: var(--green);
            }
        }
    }

    .CalendarDay__default:hover,
    [class*=CalendarDay__selected],
    .CalendarDay__hovered_span {
        background-color: var(--green);
    }

    .CalendarDay__selected_end,
    .CalendarDay__selected_end:hover {
        background-color: var(--green15);
    }

    .mi-be-chain-field,
    .mi-be-multiroom-field {
        position: relative;
        margin-right: 0;

        @extend %fieldsetStuff;
        
        label {
            @extend %inputStuff;
        }

        > span {
            &.mi-ico-dropdown {
                display: none;
            }
        }
    }

    .mi-be-promo-field label {
        @extend %fieldsetStuff;
        
        .mi-ico-code {
            display: none;
        }

        input {
            @extend %inputStuff;
        }
    }

    .mi-ico-select-dropdown {
        right: 0 !important;
        top: 0 !important;
        margin-top: 0 !important;
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        color: var(--green) !important;
    }

    // OVERRIDING STYLES
    /* +/- multiroom buttons, checked checkbox */
    .mi-be-chain-select .mi-be-chain-field .mi-ico-arrow-down-dark::before {
        color: var(--green) !important;
    }

    .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-option-adult span.react-numeric-input b i,
    .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-option-children span.react-numeric-input b i,
    .mi-checkbox-container input[type="checkbox"]:checked + .mi-checkbox-ico:before {
        background-color: var(--green) !important;
    }

    .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-option-adult span.react-numeric-input b,
    .mi-be-multiroom-select .mi-be-multiroom-dropdown ul li .mi-be-multiroom-option-children span.react-numeric-input b {
        border-color: var(--green) !important;
    }

    .mi-be-multiroom-select .mi-be-multiroom-dropdown .mi-be-multiroom-footer .mi-be-multiroom-footer-add {
        color: var(--green);
        
        &:hover {
            color: var(--green);
        }
    }

    // Nuevas clases con nuevos html
    .mi-be-occupation-select {
        .BE_nMXR_,
        .BE_3dNhx,
        .BE_Jf_3_ {
            @include font-sans();
        }

        .mi-be-occupation-dropdown {
            .BE_1LnL4 {
                color: var(--green);
            }
    
            .BE_1HJeN,
            .BE_1HJeN:hover:not(:active) {
                box-shadow: none;
                border: 1px solid var(--green);
                color: var(--green);
            }

            .BE_1HJeN:not(:disabled):active {
                background-color: var(--green);
                color: var(--lighter-green);
            } 

            select {
                text-align: center;
            }
        }
    }

    &.mi-be-mobile {
        > .mi-be-modal {
            .mi-be-modal-header {
               padding-left: rem(60) !important;
            }

            .mi-be-modal-main .mi-be-modal-fields input.DateInput_input__focused {
                background-color: var(--green);

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: var(--white);
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: var(--white);
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: var(--white);
                }
            }

            .mi-be-multiroom-footer,
            .mi-be-modal-footer {
                button.mi-be-multiroom-footer-confirm,
                button.mi-be-button.mi-be-multiroom-footer-add,
                button.mi-be-button.mi-be-date-picker-confirm {
                    @extend %buttonStuff;
                    
                    --font-size: var(--font-size-xxsmall);
                    --border: var(--green);

                    width: 48%;
                    
                    &:not(:last-child) {
                        --color: var(--green);
                        --bg: var(--white);
                    }
                    
                    &:last-child {
                        --bg: var(--green);
                        --color: var(--white);
                    }
                }
            }
        }
    }
}

