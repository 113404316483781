@mixin type-post-2 {
    --font-size-title: var(--font-size-large);

    .content {
        background-color: var(--green);
        color: var(--light-green);
        height: 45%;
        top: auto;
    }

    .categories, 
    .time {
        top: var(--padding-xxs);
    }
    
    .time {
        right: var(--padding-xxs);
    }

    .categories {
        left: var(--padding-xxs);
    }

    .title {
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}

.new.--type-post-2 {
    @include type-post-2;
}
