.rewards-ventajas {
    --h1-font-size: var(--font-size-xxxlarge);
    --font-size-cover: var(--font-size-xsmall);
    --height-cover: 50vh;
    --cols: 2;

    --cover-max-width: #{rem(800)};
    --cards-max-width: #{rem(1300)};

    --gap: var(--padding-xs);
    --padding: var(--padding-s);
    --padding-inside: var(--header-height) 0 0;
    --padding-cards: 0;
    --radius: var(--padding-xs);

    --card-title-font-size: var(--font-size-xxlarge);
    --card-subtitle-font-size: var(--font-size-small);
    --card-reward-font-size: var(--font-size-small);
    --card-text-font-size: var(--font-size-small);

    @media (max-width: $smartphone) {
        --h1-font-size: var(--font-size-xxlarge);
        --font-size-cover: var(--font-size-xsmall);
        --cols: 1;
        --height-cover: 90vh;
        --card-title-font-size: var(--font-size-large);
        --card-subtitle-font-size: var(--font-size-xsmall);
        --card-reward-font-size: var(--font-size-xsmall);
        --card-text-font-size: var(--font-size-xxsmall);
        --padding: 0;
        --padding-inside: calc(var(--header-height) * 1.5) var(--padding-s) 0;
        --padding-cards: 0 var(--padding-s) var(--padding-s);
    }
    
}


.rewards-ventajas {
    width: 100%;
    padding: 0 var(--padding);
    
    > figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--height-cover);
        object-fit: cover;
        object-position: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: .2);
        }
    }

    > header {
        padding: var(--padding-inside);
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;

        color: var(--white);

        > .title {
            @include font-serif(1);
            font-size: var(--h1-font-size);
            margin-bottom: .02em;
            color: var(--fluor);
        }

        > .text {
            column-count: var(--cols);
            @include font-sans-medium();
            font-size: var(--font-size-cover);
            max-width: var(--cover-max-width);
            margin: 0.5em 0 2em;
            
            @media (max-width: $smartphone) {
                margin: 0.5em 0 2em;

                p:not(:first-child) {
                    margin-top: 1em;
                }
            }
            

        }
    }
}

.rewards-ventajas__center {
    padding: var(--padding);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--dark-green);

    > .button-kora {
        --bg: var(--fluor);
        --fill: var(--fluor);
        --bg-circle: var(--dark-green);
        --border-circle: var(--dark-green);
        --color: var(--dark-green);
        --border: var(--fluor);
    }

    .nota {
        @include font-sans();
        font-size: var( --card-text-font-size);
        margin-top: 1em;
    }
}

.rewards-ventajas__cards {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--gap);
    max-width: var(--cards-max-width);
    margin: 0 auto;
    padding: var(--padding-cards);
   

    .card-reward-tier {
        background-color: var(--lighter-green);
        border-radius: var(--radius);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 var(--padding-xs) var(--padding-m);
        

        > .title {
            @include font-serif(.8);
            text-align: center;
            width: 100%;
            color: var(--dark-green);
            font-size: var(--card-title-font-size);
            padding: .3em 0 0;
            margin-bottom: 0 0 .1em;
        }

        .subtitle {
            text-align: center;
            @include font-sans();
            color: var(--dark-green);
            font-size: var(--card-subtitle-font-size);
            margin: 1em 0 .6em;
        }

        .main-reward {
            text-align: center;
            @include font-sans-bold(1);
            color: var(--dark-green);
            background-color: var(--fluor);
            border-radius: 1em;
            font-size: var(--card-subtitle-font-size);
            width: auto;
            margin: 0 auto 1.8em;
            padding: .3em 1.5em;
        }

        > ul {
            @include font-sans();
            font-size: var(--card-text-font-size);
            color: var(--dark-green);
            width: 100%;

            li {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 1em;
                margin-bottom: .2em;

                @media (min-width: $smartphone) {
                    padding-left: 1em;
                }

                .icon {
                    position: relative;
                    flex: 0 0 2em;
                    width: 2em;
                    height: 2em;
                    border-radius: 50%;
                    border: .5px solid var(--dark-green);

                    svg {
                        position: absolute;
                        top: 20%;
                        left: 20%;
                        width: 60%;
                        height: 60%;
                        fill: var(--dark-green);
                    }
                }
            }
        }
    }

    @media (max-width: $smartphone) {
        display: flex;
        gap: var(--gap);

        > div {
            flex: 1 0 80vw;
        }
        
        @include scroll-horizontal();
    }
}