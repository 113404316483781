@use "sass:math";

.not-found-module {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 70vh;
    padding: var(--header-height) var(--padding-s);

    .content {
        flex-direction: column;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    a {
        margin-top: var(--padding-l);
    }
}
