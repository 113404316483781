.widget-buttons {
    --padding-v: 25vh;
    --padding-h: var(--padding-xxl);
    --button-h: #{rem(54px)};
}

.widget-buttons {
    padding: var(--padding-v) var(--padding-h);
    width: 100vw;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: var(--padding-xs);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    > div {
        width: 50%;
        height: var(--button-h);
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
