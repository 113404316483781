@media (max-width: $smartphone) {
    .widget-locations-slider {
        --width: 100vw;
        --width-content: 100%;
        --height: auto;
        --width-visor: 80vw;
        --height-visor: 100vw;
        --offset-ul: calc(-1.4 * var(--font-size-title));
        --padding-content: 0;
        --padding-controls: 0;
        --padding-h: 0;
        --gap-controls: var(--padding-xxs);
                
        --font-size-location: var(--font-size-xxsmall);
        --font-size-claim: var(--font-size-small);
        --font-size-title-small: var(--font-size-xxlarge);
        --font-size-title: var(--font-size-xxxxlarge);
        --font-size-text: var(--font-size-small);
        --font-size-icons: var(--font-size-xxsmall);
        --icon-size: #{rem(18)};
    }

    .widget-locations-slider {
        width: 100vw;
        max-height: 100vh;
        padding-bottom: 0;
        margin-top: var(--padding-l);
        margin-bottom: var(--padding-l);
        overflow: hidden;
        
        display: flex;
        flex-direction: column;
    }

    .widget-locations-slider > ul {   
        flex: 0 0 auto; 
        position: relative;
        height: auto;
        padding: 0;
        margin-top: var(--offset-ul);
    }

    .widget-locations-slider__item {
        @include z-index(1);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding:  0 var(--padding-s) 0;
        top: 0;

        &:first-child {
            position: relative;
        }

        .title {
            margin-top: 0;
            color: var(--white);
            font-size: var(--font-size-title-small);
            
            strong {
                font-size: var(--font-size-title);
            }

            .mask:last-child > span {
                --font-size-sans: calc(var(--font-size-title-small) * 0.93);
            }
        }

        .location {
            color: var(--white);
            letter-spacing: normal;
            width: auto;
            margin-top: 0;
            margin-bottom: 1.2em;
        }

        .icons {
            order: 3;
            justify-content: space-between;
            flex-wrap: wrap;

            > li {
                flex: 0 0 48%;
            }
        }

        .claim {
            order: 4;
            width: 100%;
            text-align: center;
            height: 2.4em;
            padding: 0 var(--padding-s);
            margin-bottom: 0;
        }

        .description {
            display: none;
        }
    }

    .widget-locations-slider__visor {
        @include z-index(0);
        margin: 0 auto 0 10vw;
        flex: 1 1 auto; 
        height: var(--height-visor);
                
        &::after {
            position: absolute;
            top:0;
            left:0;
            right: 0;
            bottom: 0;
            content: '';
            background: linear-gradient(0deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,0) 65%);
            //background-color: var(--primary-bg);
            @include z-index(10);
        }
    }

    .widget-locations-slider > .controls:not(.--auto) {
        flex: 0 0 auto; 
        position: relative;
        height: auto;
    }
}





