@use "sass:math";

.rewards-signup {
    --text-color: var(--dark-green);

    --max-width: #{math.div(796px, 16px) * 1rem};
    --max-width-body: #{math.div(540px, 16px) * 1rem};
    --icon-size: #{math.div(12px, 16px) * 1rem};
    --font-size-title: var(--font-size-large);
    --padding: 0 var(--font-size-xsmall);
    --font-size-input: var(--font-size-small);
    --font-size-input-small: var(--font-size-xxsmall);

    --card-text-font-size: var(--font-size-small);

    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --padding: 0 var(--font-size-xsmall);
        --font-size-input: var(--font-size-xxsmall);
        --font-size-input-small: var(--font-size-xxxsmall);
        --card-text-font-size: var(--font-size-xxsmall);
    }
}

.rewards-activation,
.rewards-signup.rewards-signedup {
    --padding: var(--padding-xxxl) var(--font-size-xsmall);

    @media (max-width: $smartphone) {
        --padding: var(--padding-xl) var(--font-size-xsmall);
    }
}

.rewards-activation,
.rewards-signup.rewards-signedup {
    .mi-club-signup__title + p {
        display: block !important;
    }
}

.rewards-activation,
.rewards-signup {
    @include font-sans();
    color: var(--dark-green);
    padding: var(--padding);

    .mi-s-color-link {
        color: var(--fluor);
    }
    
    .mi-form-checkbox__title,
    .mi-form-password-test {
        font-size: var(--font-size-input-small);
    }

    .mi-club-signup__title + p {
        display: none;
    }

    .mi-club-signup {
        padding: 0;
        max-width: var(--max-width);

        > .mi-club-signup__logo {
            display: none;
        }

        .mi-form {
            --form-space: var(--font-size-xxxsmall);
            --form-input-space: .5em;

            margin-top: 0;

            > small {
                font-size: calc(var(--card-text-font-size) * .8);
                text-align: center;
                margin-bottom: 3em;
                display: none;
            }

            .mi-form-item__title {
                font-size: var(--font-size-xxxsmall);
            }

            select,
            .mi-form-item__input {
                padding-left: 1.5em;
                padding-right: 1.5em;
            }

            .mi-form-item:not(.mi-form-checkbox) {
                label {
                    display: flex;
                    align-items: center;
    
                    > span {
                        flex: 0 0 20%;

                        @media (max-width: $smartphone) {
                            flex: 0 0 30%;
                            font-size: 10px;
                        }
                    }

                    .mi-form-item__password-wrapper {
                        flex: 0 0 80%;

                        @media (max-width: $smartphone) {
                            flex: 0 0 70%;
                            font-size: var(--font-size-input);
                        }
                    }

                    @media (max-width: $smartphone) {
                        select,
                        input {
                            font-size: var(--font-size-input);
                        }
                    }
                }                
            }
        }

        .mi-club-signup__title {
            @include font-serif(1);
            font-size: var(--font-size-title);
            font-weight: 800;
            color: var(--fluor);
            text-transform: uppercase;
            margin: .5em 0 .5em;

            p {
                @include font-sans();
            }

            @media (max-width: $smartphone) {
                margin: 1.5em 0 .6em;
            }
        }

        hr {
            display: none;
        }

        .mi-form-item__title {
            @include font-sans();
            font-weight: 800;
            padding-left: 1em;
            margin-bottom: .6em;
            text-transform: uppercase;
        }

        .mi-form-item__error {
            @include font-sans();
            padding-left: 1em;
        }

        .mi-form-item__select,
        .mi-form-item__date,
        .mi-form-item__input {
            border-color: var(--primary-brand);
            color: var(--primary-brand);
            border-radius: 2em;
        }

        .mi-form__submit {
            text-transform: uppercase;
            border-radius: 2em;
        }
    }
}
