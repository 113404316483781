@use "sass:math";

.locations-slider {
    --width: 100%;
    --gap: 0;
    --height: auto;
    --max-height: auto;
    --padding-v: var(--padding-xxl);
    --padding-h: var(--padding-s);
    --border-radius: #{math.div(256px, 16px) * 1rem};
    --border-bottom-radius: #{rem(100)};
    --font-title-serif: var(--font-size-xl);
    --font-title-sans: var(--font-size-xsmall);
    --width-slide: calc(100% / 3);
    --primary-color: var(--green);
    
    @media (max-width: $smartphone) {
        --font-title-serif: var(--font-size-xl);
        --font-title-sans: var(--font-size-xsmall);
        --padding-v: var(--padding-xl);
        --gap: var(--padding-h);
        --padding-h: var(--padding-s);
        --border-radius: #{math.div(256px, 16px) * 1rem};
        --border-bottom-radius: #{rem(40)};
        --width-slide: 100%;
    }
}

.locations-slider {
    @include z-index(1);
    user-select: none;
    background-color: var(--white);
    border-bottom-left-radius: var(--border-bottom-radius);
    border-bottom-right-radius: var(--border-bottom-radius);
    padding: var(--padding-v) 0;
    width: var(--width);
    position: relative;

    .title-default {
        margin-bottom: var(--padding-l);

        @media (max-width: $smartphone) {
            margin-bottom: 0;
        }
    }
    
    &__header {
        padding: 0 var(--padding-h);
    }

    &__slider {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }

        > .holder {
            order: 1;
            position: relative;
            height: var(--height);
            max-height: var(--max-height);
            display: flex;
            flex-wrap: nowrap;
            overflow: hidden;
            gap: var(--gap);
            width: 100%;
        }
    }

    &__item {
        color: var(--green);
        position: relative;
        flex: 0 0 var(--width-slide);
        width: var(--width-slide);
        padding: var(--padding-s) var(--padding-h);
        height: 100%;
        
        > div {
            @include aspect-ratio(308, 123);
            border-radius: var(--border-radius);
            overflow: hidden;
            text-align: center;
        }

        figure {
            @include z-index(0);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .name,
            .loc {
                @include z-index(1);
                position: relative;
            }
    
            .name {
                @include font-serif(0.936);
                font-size: var(--font-title-serif);
                text-transform: uppercase;
            }
    
            .loc {
                @include font-sans();
                font-size: var(--font-title-sans);
            }
        }
        
    }

    /*&__controls {
        order: 2;
        position: relative;
        width: 100%;
        height: auto;
        padding: 0 var(--padding-h);
        margin-top: var(--padding-xl);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        
        button {
            pointer-events: all;
            @include basic-a();
            @include font-sans(1);
            background-color: transparent;
            margin: 0 var(--padding-m);
        }
    }*/
}

.locations-slider .controls {
    order: 2;

    @media (max-width: $smartphone) {
        padding-bottom: 0;
    }
}
