@mixin type-post-6 {
    --font-size-title: var(--font-size-xlarge);

    .mask svg path {
        fill: var(--light-green);
    }

    .thumbnail {
        @include z-index(0);
        bottom: 0;
        height: 100%;
        left: 0;
        width: 100%;
    }

    .content {
        color: var(--dark-green);
    }

    .categories, 
    .time {
        top: var(--padding-xs);
    }
    
    .time {
        right: var(--padding-xs);
    }

    .categories {
        left: var(--padding-xs);
    }

    .title {
        bottom: var(--padding-m);
        color: var(--dark-green);
    }
}

.new.--type-post-6 {
    @include type-post-6;
}
