@use "sass:math";

:root {
  --y-header: 0;

  --padding-xxxxl: #{math.div(220px, 16px) * 1rem};
  --padding-xxxl: #{math.div(110px, 16px) * 1rem};
  --padding-xxl: #{math.div(90px, 16px) * 1rem};
  --padding-xl: #{math.div(80px, 16px) * 1rem};
  --padding-l: #{math.div(50px, 16px) * 1rem};
  --padding-m: #{math.div(30px, 16px) * 1rem};
  --padding-s: #{math.div(20px, 16px) * 1rem};
  --padding-xs: #{math.div(16px, 16px) * 1rem};
  --padding-xxs: #{math.div(12px, 16px) * 1rem};
  --padding-xxxs: #{math.div(8px, 16px) * 1rem};

  --header-height: #{math.div(78px, 16px) * 1rem};
  --controls-height: #{math.div(154px, 16px) * 1rem};
  --secure-header-height: #{math.div(98px, 16px) * 1rem};
  
  --logo-height: #{math.div(50px, 16px) * 1rem};
}

@media (max-width: $smartphone) {
  :root {}
}
