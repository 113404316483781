@use "sass:math";

.block-image {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);
    --max-width: #{math.div(910px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-m);
    }

}

.block-image {
    padding: var(--padding-v) var(--padding-h);

    > div {
        margin: 0 auto;
        max-width: var(--max-width);
    }
}
