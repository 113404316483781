html {
    width: 100%;
    height: 100%;
}

body {
    width: 100vw;
    min-height: 100%;
    color: var(--primary-color);
    background: var(--secondary-bg);

    main {
        width: 100%;
    }
    
    &:not(.__scroll-manual) {
        overflow: hidden;
        height: 100vh;

        .wrap {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100vw;
        }

        // main {
        //     @include z-index($z-index-wrap);
        //     position: fixed;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        // }

        article {
            @include z-index(2);
        }
    }
    
    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
}

.icon {
    display: inline-block;
    
    svg {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.line-parent {
    overflow: hidden;
}

*[aria-current="page"] {
    pointer-events: none;
    text-decoration: none;
}

[disabled] {
    pointer-events: none;
    opacity: .6;
}

// Tweakpane
.tp-dfwv {
    @include z-index($z-index-tweakpane);
}

.title-split {
    text-transform: uppercase;

    span {
        display: block;
    }

    > *:first-child {
        @include font-serif();
        font-size: var(--font-title-serif);
    }
    
    > *:last-child {
        @include font-sans-display();
        font-size: var(--font-title-sans);
    }
}

.headline {
    @media (max-width: $smartphone) {
        > *:last-child {
            margin-top: -1rem;
        }
    }
    
    @media (min-width: $smartphone) {
        .line-parent:not(:first-child) {
            margin-top: -0.55em
        }
    }
}

#scene-kora {
    position: fixed;
    @include z-index(-1);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
