.news-list {
    --font-title-serif: var(--font-size-xxxxxxxxxxlarge);
    --font-title-sans: var(--font-size-xxxxxxxxxlarge);
    --font-size-text: var(--font-size-base);
    --padding-top-h: var(--padding-xl);
    --padding-top-v: var(--padding-xxxl);
    --padding-h: var(--padding-m);
    --padding-top: var(--padding-xl);
    
    @media (max-width: $smartphone) {
        --font-title-serif: var(--font-size-xxxlarge-2);
        --font-title-sans: var(--font-size-xxxlarge);
        --font-size-text: var(--font-size-small);
        --padding-top-v: var(--padding-m);
        --padding-top-h: var(--padding-s);
        --padding-h: var(--padding-xs);
        --pading-top: var(--padding-m);
    }
}

.news-list {
    color: var(--primary-color);
    padding-top: var(--padding-top);
    
    > .top {
        padding: 0 calc(4 * var(--padding-top-h)) 0 var(--padding-top-h);
        
        .text {
            @include font-sans();
            font-size: var(--font-size-text);
            margin: var(--padding-s) 0 var(--padding-l);
        }
    }

    > .categories {
        font-size: var(--font-size);
        padding: 0 var(--padding-h);
    }
    
    > .pagination ol {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: var(--padding-xl) 0;
        padding: 0 var(--padding-h);

        li {
            margin-right: rem(20);
        }
    }

    > .list {
        padding: 0 var(--padding-h);

        .new {
            &.--disabled {
                display: none;
            }
        }

        li {
            z-index: 1;

            &:hover {
                z-index: 2;
            }
        }

        li > div {
            position: relative;
            z-index: 1;
            
            a {
                transition: transform .4s var(--ease-out-quad);
            }

            &.hovered {
                z-index: 2;

                a {
                    transform: scale3d(1.02, 1.02, 1);
                    transition: transform .1s var(--ease-in-quad);
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        padding: var(--padding-l);
    }

    @media (max-width: $smartphone) {
        > .top {
            padding: 0 var(--padding-s);
        }

        > .categories {
            display: flex;
            margin: var(--padding-s) 0 var(--padding-m);
            padding-bottom: var(--padding-xxs);
            overflow-x: auto;

            .button-kora {
                margin-right: var(--padding-xxs);
            }
        }

        > .list {
            li:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
        }
    }

    @media (min-width: $smartphone) {
        > .top {
            .text {
                margin-bottom: var(--padding-xxl);
                text-indent: rem(15);
                max-width: rem(400);
            }
        }

        > .categories {
            display: flex;
            justify-content: center;
            gap: var(--padding-s);
            margin: var(--padding-l) 0 var(--padding-xxxl);
        }

        > .list {
            display: flex;
            flex-wrap: wrap;
            gap: var(--padding-xxs);
    
            li {
                width: calc(100% / 3 - 2 * var(--padding-xxs) / 3);
            }
        }

        &__footer {
            padding: var(--padding-xl);
        }
    }
}
