// #Curtain,
// #Preloader {
//     --font-size: var(--font-size-s);
//     --color: var(--dark-green);
//     --bg: var(--green);

//     @media (max-width: $smartphone) {
//         --font-size: 4vw;
//     }
// }

#Curtain,
#Preloader {
    @include z-index($z-index-loader);
    
    position: fixed;
    top: 0;
    left: 0;
    
    color: var(--color);
    background-color: var(--bg);
    
    pointer-events: none;
}

#Curtain {
    --bg: var(--primary-bg);
    
    height: 100vh;
    width: 100vw;
    transition: background-color .3s linear;
}

#Preloader {
    --bg: var(--dark-green);

    right: 0;
    bottom: 0;

    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    .logo {
        position: relative;
    }
    
    .icon {
        display: block;
        height: 52px;
        width: 250px;

        svg {
            fill: var(--green);
        }
    }

    @media (max-width: $smartphone) {
        .icon {
            height: 35px;
            width: 167px;
        }
    }

    .overlay {
        @include z-index(1);

        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 0%;
        opacity: .3;
        overflow: hidden;
        transform-origin: right center;

        .icon {
            left: 0;
            position: absolute;
            top: 0;

            svg {
                fill: var(--white);
            }
        }
    }

    // opacity: 0 !important;

    @include font-sans(1);
    font-size: var(--font-size);
    text-transform: uppercase;

    .progress {
        background-color: var(--grey);
        height: rem(1);
        margin-top: var(--padding-s);
        position: relative;
        width: rem(80);
    }
    
    .thumb {
        background-color: var(--white);
        height: 100%;
        left: 0;
        position: absolute;
        transform: scale3d(0, 1, 1);
        transform-origin: center left;
        width: 100%;
    }
}
