@use "sass:math";

:root {
    --zoom: 1;
    --aspect-ratio: 1;

    --font-sans: 'EloquiaText', sans-serif;
    --font-sans-display: 'EloquiaDisplay', sans-serif;
    --font-serif: 'Moneta', sans-serif;

    --font-size-xxxxxxxxxxxlarge: #{math.div(250px, 16px) * 1rem};
    --font-size-xxxxxxxxxxlarge: #{math.div(220px, 16px) * 1rem};
    --font-size-xxxxxxxxxlarge: #{math.div(205px, 16px) * 1rem};
    --font-size-xxxxxxxxlarge: #{math.div(190px, 16px) * 1rem};
    --font-size-xxxxxxxlarge: #{math.div(180px, 16px) * 1rem};
    --font-size-xxxxxxlarge: #{math.div(142px, 16px) * 1rem};
    --font-size-xxxxxlarge: #{math.div(96px, 16px) * 1rem};
    --font-size-xxxxlarge: #{math.div(90px, 16px) * 1rem};
    --font-size-xxxlarge-2: #{math.div(82px, 16px) * 1rem};
    --font-size-xxxlarge: #{math.div(80px, 16px) * 1rem};
    --font-size-xxlarge: #{math.div(52px, 16px) * 1rem};
    --font-size-xlarge: #{math.div(48px, 16px) * 1rem};
    --font-size-xl-2: #{math.div(44px, 16px) * 1rem};
    --font-size-xl: #{math.div(40px, 16px) * 1rem};
    --font-size-large-2: #{math.div(36px, 16px) * 1rem};
    --font-size-large: #{math.div(34px, 16px) * 1rem};
    --font-size-medium-2: #{math.div(27px, 16px) * 1rem};
    --font-size-medium: #{math.div(22px, 16px) * 1rem};
    --font-size-m: #{math.div(20px, 16px) * 1rem};
    --font-size-base: #{math.div(18px, 16px) * 1rem};
    --font-size-small: #{math.div(16px, 16px) * 1rem};
    --font-size-xsmall: #{math.div(14px, 16px) * 1rem};
    --font-size-xxsmall: #{math.div(12px, 16px) * 1rem};
    --font-size-xxxsmall: #{math.div(11px, 16px) * 1rem};
    --font-size-xxxxsmall: #{math.div(8px, 16px) * 1rem};

    --line-height-sans: 1.25;

    --min-font-size: 8px;
    --max-font-size: 100px;
    --font-size: clamp(var(--min-font-size), 1.1111vw * var(--zoom), var(--max-font-size));
    font-size: var(--font-size);
    
    @media (max-width: $smartphone) {
        --font-size: 16px;
    }
}

@mixin font-sans-display($line-height: 0) {
    font-family: var(--font-sans-display);
    font-weight: 100;
    @if $line-height > 0 { line-height: $line-height }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-sans-medium($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 500;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-sans-bold($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: 600;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans); }
}

@mixin font-serif($line-height: 0) {
    font-family: var(--font-serif);
    font-weight: 400;
    @if $line-height > 0 { line-height: $line-height; }
    @else { line-height: var(--line-height-sans);  }
}
