
@use "sass:math";

.block-title-video {
    --height: auto;
    --mediaWidth: 60vw;

    @media (min-width: $smartphone) {
        --mediaWidth: 20vw;
    }

    height: var(--height);
    color: var(--primary-color);

    .media {
        width: var(--mediaWidth);
        margin: 0 auto;

        video {
            object-fit: cover;
            object-position: center center;
        }
    }

    .char {
        opacity: .2;
    }
    
    //@media (max-width: $smartphone) {
        align-items: center;
        display: flex;
        margin-top: 0;

        > div {
            width: 100%;

            display: flex;
            flex-direction: column;
        }

        .title-default {
            order: 2;
        }

        .media {
            order: 1;
            margin-top: 0;
            margin-bottom: var(--padding-l);
        }
    //}
}