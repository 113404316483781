@mixin type-post-1 {
    --font-size-title: var(--font-size-xxxlarge);

    @media (max-width: $smartphone) {
        --font-size-title: #{rem(62)};
    }

    .mask svg path {
        fill: var(--green);
    }

    .content {
        color: var(--white);
    }

    .categories, 
    .time {
        top: var(--padding-xxs);
    }
    
    .time {
        right: var(--padding-xxl);
    }

    .categories {
        left: var(--padding-xxl);
    }
    
    .title {
        color: var(--white);
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 80%;
        justify-content: space-between;
        top: 10%;
        left: 50%;
        transform: translate3d(-50%, 0, 0);

        span {
            margin: 0;
        }
    }
}

.new.--type-post-1 {
    @include type-post-1;
}
