.block-legal {
    --font-size-title: var(--font-size-xxxxxxlarge);
    --font-size-subtitle: var(--font-size-xlarge);
    --font-size-third: var(--font-size-large);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xxxl);
    --padding-h: var(--padding-l);
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xxlarge);
        --font-size-subtitle: var(--font-size-large);
        --font-size-third: var(--font-size-base);
        --font-size: var(--font-size-small);
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
    }
}

.block-legal {
    @include font-sans();
    color: var(--primary-color);
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);

    h1 {
        @include font-serif(1);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-l);
    }
    
    h2 {
        @include font-serif();
        font-size: var(--font-size-subtitle);
        margin-bottom: var(--padding-m);
    }

    h3 {
        font-size: var(--font-size-third);
        margin-bottom: var(--padding-s);
    }

    p,
    ul {
        margin-bottom: var(--padding-s);
        max-width: rem(850);
        
        + h2 {
            margin-top: var(--padding-l);
        }
        
        + h3 {
            margin-top: var(--padding-m);
        }
    }

    ul {
        padding-left: var(--padding-s);

        li {
            list-style: initial;
        }
    }

    a {
        color: var(--primary-color);
        word-break: break-word;
    }

    @media (max-width: $smartphone) {
        h1 {
            margin-bottom: var(--padding-m);
        }
    }
}
