@use "sass:math";

.parallax-card {
    --margin-b: var(--padding-xs);
    --icon-size: #{math.div(45px, 16px) * 1rem};
    // --icon-size-circle: #{math.div(34px, 16px) * 1rem};
    // --icon-size-plus: #{math.div(14px, 16px) * 1rem};
    --font-size-card: var(--font-size-xsmall);
    --font-size-content: var(--font-size-xsmall);

    @include font-sans();
    color: var(--secondary-color);
    display: flex;

    &.--right {
        justify-content: flex-end;
    }

    .wrapper {
        background-color: var(--secondary-bg);
        border-radius: rem(16);
        padding: 0;
        position: relative;
        transition: width .8s var(--ease-in-out-quart), height .8s var(--ease-in-out-quart);

        height: var(--height-card);
        width: var(--width-card);

        // .toggle {
        //     background-color: transparent;
        //     padding: 0;

        //     .icon--plus {
        //        align-items: center;
        //        display: flex;
        //        justify-content: center;
   
        //        border: 1px solid currentColor;
        //        border-radius: 50%;
        //        height: var(--icon-size-circle);
        //        min-width: var(--icon-size-circle);
        //        width: var(--icon-size-circle);
   
        //         svg {
        //             height: auto;
        //             width: var(--icon-size-plus);
        //         }
        //     }
        
        //     @media (max-width: $smartphone) {
        //         &.dkst {
        //             display: none;
        //         }
        //     }

        //     @media (min-width: $smartphone) {
        //         &.mbl {
        //             display: none;
        //         }
        //     }
        // }

        // .title {
        //     height: calc(1.15 * var(--font-size));
        //     overflow: hidden;

        //     span {
        //         display: block;
        //     }
        // }
    }

    .content {
        padding: var(--padding-m) var(--padding-xxs) var(--padding-s);
        text-align: left;
        font-size: var(--font-size-content);

        // > * { opacity: 0; }
    }

    .card {
        background-color: transparent;
        align-items: center;
        display: flex;
        padding: 0;
        text-align: center;
        font-size: var(--font-size-card);
        
        .--thumb {
            height: var(--icon-size);
            min-width: var(--icon-size);
            width: var(--icon-size);

            svg {
                fill: currentColor;
            }
        }
    }
    
    @media (max-width: $smartphone) {
        --font-size-card: var(--font-size-base);
        margin-bottom: var(--padding-s);

        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            &.--open {
                height: calc(var(--height-card) + var(--height-content));
                width: 100%;
            }
        }

        .card {
            height: calc(2 * var(--padding-s) + var(--icon-size));
            gap: var(--padding-xs);
            justify-content: center;
            padding: var(--padding-s);
            min-width: rem(250);
        }

        .content {
            padding: var(--padding-xxxs) var(--padding-s) var(--padding-s);
        }
    }

    @media (min-width: $smartphone) {
        --icon-size: #{math.div(55px, 16px) * 1rem};

        &.margin-top-1 { margin-top: 15vh; }
        &.margin-top-2 { margin-top: 25vh; }
        &.margin-top-3 { margin-top: 40vh; }
        // &.margin-top-4 { margin-top: 100vh; }
        // &.margin-bottom-1 { margin-bottom: 25vh; }
        // &.margin-bottom-2 { margin-bottom: 50vh; }
        // &.margin-bottom-3 { margin-bottom: 75vh; }
        // &.margin-bottom-4 { margin-bottom: 100vh; }
    
        // &.--left {
        //     &.padding-1 { padding-left: 5vh; }
        //     &.padding-2 { padding-left: 10vh; }
        //     &.padding-3 { padding-left: 15vh; }
        //     &.padding-4 { padding-left: 0; }
        // }

        // &.--right {
        //     &.padding-1 { padding-right: 5vh; }
        //     &.padding-2 { padding-right: 10vh; }
        //     &.padding-3 { padding-right: 15vh; }
        //     &.padding-4 { padding-right: 0; }
        // }

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            // .toggle {
            //     bottom: var(--padding-xs);
            //     position: absolute;
            //     right: var(--padding-xs);
            // }

            &.--open {
                height: max(var(--height-card), var(--height-content));
                width: calc(var(--width-content) + var(--width-card));
            }
        }

        .card {
            flex-direction: column;
            height: rem(200);
            width: rem(200);
            min-width: rem(200);
            justify-content: center;
            padding: var(--padding-xs); 

            .--thumb {
                margin-bottom: var(--padding-s);
            }
        }

        .content {
            padding: var(--padding-l) var(--padding-m) var(--padding-l) 0;
            min-width: rem(400);
            width: rem(400);
        }
    }
}
