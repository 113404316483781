@mixin billboardMobileShared () {
    overflow: hidden;
    height: auto;
    min-height: 100vh;

    .wrapper.--portals {
        // height: auto;
        min-height: 100%;
        padding: 10vh 0;
        position: static;
    }

    .wrapper.--column {
        margin-top: -5vh;
    }

    > * {
        opacity: 1;
    }

    .bg {
        .media-holder--auto {
            height: 100%;
        }

        img,
        video {
            height: 100%;
            object-fit: cover;
        }
    }

    .wrapper {
        perspective: 1550px;
        transform-style: preserve-3d;
    }

    .window {
        pointer-events: initial;

        -webkit-mask-image: url(https://kora.cuchillo.tools/wp-content/uploads/2022/11/Mask.png);
        mask-image: url(https://kora.cuchillo.tools/wp-content/uploads/2022/11/Mask.png);

        mask-size: 100%;

        .ov {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background: var(--black);
            opacity: .3;
        }

        figure:last-child {
            position: absolute;
            left: 0;
            top: 0;
        }
    }
}

@mixin billboardLandscape () {
    // height: auto;
    // min-height: 100vh;

    // .wrapper.--portals {
    //     height: auto;
    //     min-height: 100vh;
    //     position: static;
    //     padding: 0 10vh;
    // }
}

@mixin billboardPortrait () {
    .window {
        position: absolute;
    }

    // .wrapper {
    //     padding-bottom: var(--padding-xl);
    // }

    .wrapper.--portals {
        > div {
            height: 100%;
            width: auto;
            overflow: hidden;
        }

        .holder {
            -webkit-overflow-scrolling: touch;
            overflow: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            scroll-padding: var(--padding-s);
            padding-left: var(--padding-h);
            padding-right: var(--padding-h);
            
            align-items: center;
            display: flex;
            height: 100%;
            width: auto;
            gap: var(--gap);

            transform-style: preserve-3d;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .window {
            margin-top: calc(-1 * var(--padding-l));
            
            position: relative;
            scroll-snap-align: center;

            &:first-child {
                
            }
        }
    }
}

.block-billboard-home {
    @media (max-width: $smartphone) {
        --text-max-width: #{rem(320)};
        --window-width: 70vw;
        --window-height: calc(var(--window-width) * 1.2857);
        --padding-h: calc((100vw - var(--window-width))/2);
        --gap: var(--padding-s);

        @include billboardMobileShared();
        @include billboardPortrait();


        // .button-kora {
        //     top: calc(100vh - 200px);
        // }
    }

    @media (max-height: $smartphone) {
        --padding-h: var(--padding-s);

        @include billboardMobileShared();
        @include billboardLandscape();

        .button-kora {
            top: calc(100vh - 50px);
        }
    }
}
