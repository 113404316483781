@use "sass:math";

#Footer {
    --font-size: var(--font-size-small);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xl);
    --bg: var(--dark-green);
    --color-light: var(--white);
    --color-dark: var(--green);
    --logo-width: #{math.div(165px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-s);
    }
}

#Footer {
    @include z-index(1);
    overflow: hidden;
    background-color: var(--bg);

    .wrapper {
        @include font-sans();
        
        color: var(--color-light);
        display: flex;
        font-size: var(--font-size);
        padding: var(--padding-v) var(--padding-h) 0;
        position: relative;
    }
    
    .top a,
    .bottom a {
        @include basic-a();
        color: var(--color-dark);
    }
    
    .top {
        .newsletter {
            h2 {
                @include font-sans();
                color: var(--color-dark);
                font-size: var(--font-size-medium);
                margin-bottom: var(--padding-m);
            }
            
            p {
                margin-bottom: var(--padding-m);
            }
        }
    
        .form {
            margin-bottom: var(--padding-m);
        }
    
        .rrss {
            font-size: var(--font-size-medium);
    
            a {
                display: block;
                margin-bottom: var(--padding-xxxs);
            }
        }
    }

    .icon--logo-footer {
        svg {
            fill: var(--green);
        }
    }

    .center {
        position: relative;

        .button-kora {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .image {
            @include aspect-ratio(390, 441);
            position: relative;

            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }

        .mask {
            &::after {
                content: '';
                position: absolute;
                top: -5px;
                left:0;
                width: 100%;
                height: 5px;
                background-color: #041F1E;
            }
        }

        .mask {
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
        }
        figure {
            bottom: 0;
            top: 0;
            position: absolute;
            left: 0;
            right: 0;
            overflow: hidden;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        
        .mask svg {
            height: 100%;
            width: 100%;

            path {
                fill: var(--bg);
            }
        }
    }
    
    .bottom {
        .icon {
            height: rem(35);
            width: rem(166);
            margin-bottom: var(--padding-m);
        }
    
        .address {
            margin-bottom: var(--padding-l);

            a {
                color: var(--color-light);
                display: block;
                margin-bottom: var(--padding-s);
            }
    
            .mail {
                font-weight: 600;
            }
    
            .telf {
                text-decoration: underline;
            }
        }

        .legals {
            font-size: var(--font-size-xxsmall);

            a.menu-item {
                color: var(--color-light);
            }

            a {
                display: block;
                margin-bottom: var(--padding-xxs);
                text-decoration: underline;
            }
        }
    }

    .form {
        fieldset {
            border: 1px solid var(--light-green);
        }
        
        .--success {
            border: 1px solid var(--color-success);
            
            button {
                border-left: 1px solid var(--color-success);
            }
        }
        
        .--error {
            border: 1px solid var(--color-error);

            button {
                border-left: 1px solid var(--color-error);
            }
        }
    }

    @media (max-width: $smartphone) {
        .wrapper {
            flex-direction: column;
        }

        .top,
        .bottom {
            margin-bottom: var(--padding-l);
        }

        .bottom {
            order: 2;
        }

        .center {
            order: 3;
        }
    }
    
    @media (min-width: $smartphone) {
        .wrapper {
            gap: 5%;
            transform: translate3d(0, var(--translation), 0);

            align-items: flex-end;
        }

        .top,
        .center,
        .bottom {
            flex: 0 0 30%;
        }

        .top,
        .bottom {
            padding-bottom: var(--padding-m);
        }
    }
}
