.form {
    --font-size: var(--font-size-small);
    --font-size-label: var(--font-size-small);
    --input-height: #{rem(40)};
    --button-height: #{rem(54px)};

    [type="submit"] {
        --padding: #{rem(8px)}; 
        --width: #{rem(140px)};
        --bg: var(--light-green);
        --fill: var(--light-green);
        --color: var(--dark-green);
        --border: var(--light-green);
        --font-size: var(--font-size-xxsmall);
        --bg-hover: var(--green);
        --border-hover: var(--green);
        --color-hover: var(--white);
    }

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --font-size-label: var(--font-size-xxsmall);
    }
}

.form {
    font-size: var(--font-size);
    margin-left: auto;
    margin-right: auto;

    input,
    label,
    textarea,
    select,
    fieldset {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        outline: none;
        margin: 0;
        -webkit-appearance: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: 0px solid green;
        border-radius: rem(81);
        -webkit-text-fill-color: var(--green);
        transition: background-color 50000s ease-in-out 0s;
    }

    textarea {
        height: 100%;
        resize: none;
        width: 100%;
    }

    select,
    input {
        background: transparent;
        color: var(--green);
        cursor: pointer;
        padding: 0 var(--padding-s);

        &:not([type='checkbox']) {
            height: var(--input-height);
            line-height: var(--input-height);
            width: 100%;
        }
    }

    label {
        color: var(--green);
        font-size: var(--font-size-label);
        left: var(--padding-s);
        line-height: var(--input-height);
        position: absolute;
        pointer-events: none;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transform-origin: center left;
        transition: 0.2s ease-in-out;
        text-transform: uppercase;
    }

    &__wrapper:not(:last-child) {
        margin-bottom: var(--padding-m);
    }

    fieldset {
        border: 1px solid var(--green);
        border-radius: rem(81);
        height: var(--input-height);
        position: relative;

        &:not(:last-child) {
            margin-bottom: var(--padding-m);
        }
        
        &.--textarea {
            height: calc(6 * var(--input-height));

            label {
                top: var(--input-height) / 2;
            }
        }

        &.--select {
            position: relative;

            .icon {
                pointer-events: none;
                position: absolute;
                right: 0;
                top: 50%;
                height: rem(54);
                transform: translateY(-50%);
                width: rem(54);
            }
        }

        &.--file,
        &.--checkbox {
            border: 0;
            height: auto;

            &.--focus,
            &.--success,
            &.--error {
                label {
                    transform: none;
                    top: 0;
                }
            }

            &.--success {
                label {
                    border-color: var(--color-success);
                }

            } 
            
            &.--error {
                label {
                    border-color: var(--color-error);
                }
            }

            label {
                cursor: pointer;
                pointer-events: initial;
                position: static;
                transform: none;
                top: 0;
            }
        }

        &.--file {
            align-items: center;
            border: 0;
            display: flex;
            margin-bottom: 0;

            &:last-child {
                margin-bottom: var(--padding-s);
            } 
            
            label {
                border-bottom: 1px solid var(--white);
            }

            input {
                display: none;
            }

            .remove {
                background-color: transparent;
                height: rem(16);
                margin-left: var(--padding-xs);
                padding: 0;
                width: rem(16);
                
                svg {
                    height: 100%;
                    width: 100%;
                    transform: rotate(45deg);
                }
            }
        }

        &.--checkbox {
            margin: 0;
            padding: 0;
        }

        &.--success {
            border-color: var(--color-success);
        }

        &.--error {
            border-color: var(--color-error);
        }

        &.--focus,
        &.--success,
        &.--error {
            label {
                transform: scale3d(0.75, 0.75, 1) translate3d(0, -50%, 0);
                top: -50%;
                left: 5%;
            }
        }
    }

    ::placeholder,
    :-ms-input-placeholder,
    ::-ms-input-placeholder {
        color: var(--gray);
    }

    .hidden {
        display: none;
    }

    button[type="submit"] {
        @include basic-a();
        border: 1px solid var(--border);
        border-radius: var(--button-height);
        background-color: var(--bg);
        color: var(--color);
        height: var(--button-height);
        font-size: var(--font-size);
        min-width: var(--width);
        position: relative;
        padding: var(--padding);
        text-transform: uppercase;
        width: var(--width);
        box-sizing: border-box;

        @include isCursor() {
            transition: .2s ease-out;

            &:hover {
                border: 1px solid var(--border-hover);
                background-color: var(--bg-hover);
                color: var(--color-hover) !important;
            }
        }
    }

    &.--mailchimp {
        button {
            background-color: transparent;
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: calc(var(--input-height) - 2px);
            width: calc(var(--input-height) - 2px);
            border-left: 1px solid var(--light-green);

            svg {
                height: 50%;
                margin-left: 25%;
                width: 50%;

                path {
                    fill: var(--green);
                }
            }
        }

        .--success {
            button {
                border-left: 1px solid var(--color-success);
            }
        }

        .--error {
            button {
                border-left: 1px solid var(--color-error);
            }
        }
    }

    @media (max-width: $smartphone) {
        &__footer {
            display: flex;
            justify-content: center;

            .button {
                width: 100%;

                &:first-child {
                    margin-bottom: var(--padding-xs);
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        &__footer {
            display: flex;
            justify-content: flex-start;
            margin-top: var(--padding-m);

            .button {
                margin-left: var(--padding-m);
            }
        }
    }
}