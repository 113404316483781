.mi-clubarea {
    --font-size-title: var(--font-size-xlarge);
    --padding: var(--padding-xxxxl) var(--padding-s) ;
}

.mi-clubarea {
    width: 100vw; 
    @include font-sans();
    padding: var(--padding) !important;
    
    .mi-clubarea-notlogged__info-title,
    .mi-clubarea__info-title {
        @include font-serif(1);
        font-size: var(--font-size-title) !important;
        color: var(--fluor);
    }

    .mi-clubarea__logo {
        display: none;
    }

    .mi-clubarea__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        .mi-clubarea__info-level {
            height: 1.5em;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 1em;
            border-radius: 1.5em;
            background-color: var(--fluor);
            color: var(--black);
            
            span {
                font-weight: 700;
                color: var(--promary-darker);
                margin-left: .3em;
            }
        }
    }

    .react-tabs__tab-list {
        font-family: var(--font-hotel);
        font-weight: 700;
        font-size: var(--font-size-small);

        > li {
            text-transform: none;
        }
    }

    .mi-clubarea-tabs__panel {
        margin: 0 auto;
    }

    .mi-clubarea-tabinfo, .mi-clubarea-tabs__panel--password {
        max-width: rem(600);
    }

    .mi-clubarea-tabs__panel--reservations {
        max-width: rem(1100);
    }

    .mi-clubarea-tabinfo__delete {
        margin-top: 3em;
    }

    .mi-clubarea-reservation {
        background: var(--white);
    }

    @media (min-width: $smartphone) {
        .react-tabs__tab-list {
            display: flex;

            justify-content: center;
        }
    }

    .mi-clubarea-notlogged__btn {
        border-radius: 2em;
    }
}
