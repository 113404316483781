@use "sass:math";

.block-faqs {
    --bg: var(--primary-bg);
    --font-size-h1: var(--font-size-xxxxxxxxxxlarge);
    --font-size-title: var(--font-size-medium);
    --font-size-body: var(--font-size-base);
    --padding-h: var(--padding-xl);
    --padding-top: calc(var(--header-height) + var(--padding-xxl));
    --padding-bottom: var(--padding-xxxl);
    // --max-width: #{math.div(920px, 16px) * 1rem};
    --max-width-body: #{math.div(540px, 16px) * 1rem};
    --icon-size: #{math.div(12px, 16px) * 1rem};
    --bg-circle: var(--primary-color);
    --bg-cross: var(--primary-color);
    
    @media (max-width: $smartphone) {
        --font-size-h1: var(--font-size-xxxxxlarge);
        --font-size-title: var(--font-size-small);
        --font-size-body: var(--font-size-xsmall);
        --padding-h: var(--padding-s);
        --pading-top: calc(var(--header-height) + var(--padding-m));
        --padding-bottom: var(--padding-xxxl);
        --icon-size: #{math.div(12px, 16px) * 1rem};
    }
}

.block-faqs {
    color: var(--primary-color);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    @include font-sans();
    
    h1 {
        @include font-serif(0.75);
        font-size: var(--font-size-h1);
        margin-bottom: var(--padding-l);
    }

    &__ul {
        list-style: none;
        // max-width: var(--max-width);
        margin: 0 auto;
    
        > li {
            border-top: 1px solid currentColor;
    
            &:last-child { border-bottom: 1px solid currentColor; }
        }
    
        //ACORDEON
        article {
            --duration: 0.4s;
            --ease: var(--ease-in-out-quad);
        }
    
        article > button {
            --rot: 0deg;
            --strokeCross: #{math.div(2px, 16px) * 1rem};
            
            @include font-sans-bold();
            @include basic-a();
            background-color: transparent;
            color: var(--primary-color);
            position: relative;
            width: 100%;
            padding: var(--padding-s) var(--padding-l) var(--padding-s) 0;
            font-size: var(--font-size-title);
            line-height: 1;
            text-align: left;
            min-height: var(--icon-size);
    
            > span {
                position: absolute;
                right: 0;
                top: 50%;

                padding: var(--padding-s);

                border-radius: 50%;
                border: 1px solid currentColor;
    
                transform-origin: 50% 50%;
                transform: translate3d(0, -50%, 0) rotate(var(--rot));
    
                transition: transform 0.4s var(--ease);
                overflow: hidden;
    
                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    @include z-index(2);
                    transform: translate3d(-50%, -50%, 2px) scale3d(1, 1, 1);
                }
                
                &::after {
                    top: 50%;
                    left: 50%;
                    width: var(--strokeCross);
                    height: var(--icon-size);
                    background: var(--bg-cross);
                    transition: background 0.2s var(--ease), transform .5s var(--ease-in-back-out-circ);
                }
                
                &::before {
                    top: 50%;
                    left: 50%;
                    width: var(--icon-size);
                    height: var(--strokeCross);
                    background: var(--bg-cross);
                    transition: background 0.2s var(--ease), transform .5s var(--ease-in-back-out-circ);
                }
                
                span {
                    @include z-index(1);
                    background-color: var(--bg-circle);
                    height: 100%;
                    top: 50%;
                    position: absolute;
                    left: 50%;
                    width: 100%;
                    transform: translate3d(-50%, -50%, 1px) scale3d(0, 0, 1);
                    transition: transform .3s var(--ease);
                    border-radius: 50%;
                }
            }

            @include isCursor () {
                &:hover {
                    --bg-cross: var(--primary-bg);

                    span {
                        &::after,
                        &::before {
                            transform: translate3d(-50%, -50%, 2px) scale3d(1.8, 1.8, 1);
                        }

                        span {
                            transform: translate3d(-50%, -50%, 1px) scale3d(1.1, 1.1, 1);
                        }
                    }
                }
            }
        }
    
        article[aria-expanded='false'] {
            visibility: visible;
            pointer-events: initial;
        }
        
        article[aria-expanded='true'] > button {
            --bg-cross: var(--primary-bg);
            --rot: 135deg;

            span {
                &::after,
                &::before {
                    transform: translate3d(-50%, -50%, 2px) scale3d(1.5, 1.5, 1);
                }

                span {
                    transform: translate3d(-50%, -50%, 1px) scale3d(1, 1, 1);
                }
            }
        }
    
        article > div {
            max-width: var(--max-width-body);
            padding: var(--padding-xs) 0 var(--padding-m);
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        gap: var(--padding-l);
        
        &__ul {
            margin-top: var(--padding-l);
        }

        article > button {
            padding: var(--padding-m) var(--padding-xl) var(--padding-m) 0;
        }

        article > div {
            padding: var(--padding-s) 0 var(--padding-l);
        }
    }
}
