
@use "sass:math";

@media (max-width: $smartphone) {

    .block-slider-cards-3D {        
        --width: 100vw;
        --padding-h:0;
        --padding-v: var(--padding-l);
        overflow: hidden;
    }

    .block-slider-cards-3D {
        position: relative;
        padding: var(--padding-v) var(--padding-h);
    
        > .slider-cards-3D {    
            @include aspect-ratio(190, 220);
        }
    }

    ////////////////////
    /////SLIDER/////////
    ////////////////////

    .slider-cards-3D {
        --font-size-serif: #{rem(42)};
        --font-size-text: var(--font-size-xsmall);
        --font-size-sans: calc(var(--font-size-serif) * 0.93);
        --margin-title-serif: -.18em;
        --width-slide: 70vw;
        --height-slide: 100%;
        --radius: calc(var(--height-slide) * 6);
        
    
        box-sizing: border-box;
        * {
            box-sizing: border-box;
        }
    }

    .slider-cards-3D__item {
        display: none;
    }

    .slider-cards-3D {
        user-select: none;
        padding: var(--padding-block-vertical) 0;
        
        cursor: grab;    
        &:active {
            cursor: grabbing;
        }
    
        > .holder {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: nowrap;
            gap: 10px;
            perspective: 0;

            padding: 0 15vw;

            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .slider-cards-3D__card {
        position: relative;
        z-index: 0;
        top: 0;
        left: 0;
        width: var(--width-slide);
        flex: 0 0 var(--width-slide);
        height: var(--height-slide);
        background-color: white;
        border-radius: 1rem;
        overflow: hidden;
        padding: var(--padding-xs) var(--padding-xs);
    
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        white-space: normal;
    
        transform-style: preserve-3d;
        transform-origin: top center;

        scroll-snap-align: center;
    
        @media (max-width: $smartphone) {
            display: block;
            transform-origin: top left;
        }
    
        > .tapa {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: var(--primary-bg);
    
            @media (max-width: $smartphone) {
                display: none;
            }
        }
    
        > figure {
            position: absolute;
            top: 0;
            right: 0;
            width: 40%;
            height: 100%;
    
            @media (max-width: $smartphone) {
                top: auto;
                bottom: 0;
                width: 100%;
                height: 48%;
            }
    
            img {
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
    
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        &:last-child {
            > figure img {
                object-position: center top;
            }
        }
    
        .title {
            text-transform: uppercase;
            flex: 1 0 100%;
            @include font-serif(.7);
            font-size: var(--font-size-serif);        
    
            strong {
                @include font-sans-display(.7);
                line-height: var(--font-size-sans);
                font-size: var(--font-size-sans);
                margin-top: var(--margin-title-serif);
                margin-bottom: -5.2em;
            }
        }
    
        .text {
            @include font-sans();
            font-size: var(--font-size-text);
            flex: 0 0 25%;
            margin: 0 1.4em 0 0;
    
            > p:not(:first-child) {
                margin-top: 1em;
            }
    
            > p:not(:last-child) {
                margin-bottom: 1em;
            }
        }
    }

    .block-slider-cards-3D > .controls {
        display: none;
    }
}


