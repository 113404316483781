@use "sass:math";

$padding: .15em;
$marginSans: .22em;
$marginSerif: .28em;

.title-default {
    --padding-lines: #{$padding};
    --margin-sans: #{-1 * ($padding * 2 + $marginSans)};
    --margin-serif: #{-1 * ($padding * 2 + $marginSerif)};
    --font-size: #{rem(180)};
    --font-size-sans: calc(var(--font-size) * 0.93);

    &.--bigLineHeight {
        --margin-sans: calc(-.8 * .52em);
        --margin-serif: calc(-.8 * .58em);
    }

    &.--font-m {
        --font-size: var(--font-size-xxxxlarge);
    }
        
    @media (max-width: $smartphone) {
        --font-size: #{rem(70)};

        &.--font-m {
            --font-size: var(--font-size-xl);
        }
    }
}

.title-default {
    font-size: var(--font-size);
    padding-top: $marginSerif;

    @include font-sans-display(1);
    font-size: var(--font-size-sans);
    text-transform: uppercase;

    &.--center {
        text-align: center;
    }

    .__line {
        display: block;
    }
    
    .mask {
        display: block;
        overflow: hidden;
        padding: var(--padding-lines) 0;
        margin-top: var(--margin-sans);
    
        > span {
          line-height: var(--font-size-sans);
          height: var(--font-size-sans);

          > strong {
            display: inline-block;
          }
        }
    }

    strong {
        @include font-serif();
        line-height: var(--font-size-sans);
        font-size: var(--font-size);
        margin-top: var(--margin-serif);
    }

    /*
    
    > .line-serif {
        @include font-serif(1);
        font-size: var(--font-size);
        text-transform: uppercase;
        margin-top: var(--margin-serif);
    }

    > .line-sans {
        @include font-sans-display(1);
        font-size: var(--font-size-sans);
        text-transform: uppercase;
        margin-top: var(--margin-sans);
    }*/
}