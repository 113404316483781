@use "sass:math";

.block-highlight-text {
    --font-title-text: var(--font-size-xxlarge);
    
    @media (max-width: $smartphone) {
        --font-title-text: var(--font-size-xl-2);
    }
}

.block-highlight-text {
    @include font-sans();

    height: 100vh;
    
    color: var(--primary-bg);
    font-size: var(--font-size);
    text-align: center;
    margin: 50vh 0;
    position: relative;

    > .bg {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;

        img,
        video {
            height: auto;
            width: 100%;
        }
    }

    .title-default {
        --font-size: var(--font-title-text);
        text-transform: initial;
        margin-bottom: var(--padding-xxs);
        color: var(--primary-bg);
    }

    .button-kora.--hollow {
        --border: var(--primary-bg);

        &:hover {
            --border: var(--dark-green);
        }
    }

    .wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 100%;

        // height: 100vh;
        // left: 0;
        // position: absolute;
        // top: 0;
        // width: 100%;
    }

   @mixin mobile() {
        margin: 0;

        > .bg {
            opacity: 1;

            .media-holder {
                height: 100%;
            }

            img,
            video {
                height: 100%;
                object-fit: cover;
            }
        }
   } 
 
    @media (max-width: $smartphone) {
        @include isTouch() {
            @include mobile();
        }
    }
 
    @media (max-height: $smartphone) {
        @include isTouch() {
            @include mobile();
        }
    }
}
