
@use "sass:math";

.block-slider-circular {
    --padding-top: 10vw;
    --padding-bottom: 25vw;

    @media (max-width: $smartphone) {
        --padding-bottom: var(--padding-l);
    }
}

.block-slider-circular {
    position: relative;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    color: var(--primary-color);

    > .slider-circular {    
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @media (max-width: $smartphone) {
            position: relative;
        }
    }

    .header-section-default {
        padding-top: 20vh;
        padding-bottom: 30vh;
        
        @media (max-width: $smartphone) {
            padding-bottom: var(--padding-xs);
        }
    }
}

@media (max-width: $smartphone) {
    .block-slider-circular .controls {
        position: relative !important;
        height: auto !important;
        padding-top: 0;
    }
}

////////////////////
/////SLIDER/////////
////////////////////

.slider-circular {    
    --width-slide: 33.333vw;
    --aspect: 1.30;
    --height-image: calc(var(--width-slide) * var(--aspect));
    --height-slide: calc(var(--height-image)/* + var(--caption-font-size) + var(--caption-margin) + var(--caption-padding)*/);
    --width-slide-empty: 33vw;
    --radius: calc(var(--height-slide) * 6);
    --padding-block-vertical: 10vw;
        
    @media (max-width:480px) {
        --width-slide: 70vw;
        --width-slide-empty: 15vw;
        --height-slide: calc(var(--height-image)/* + var(--caption-font-size) + var(--caption-margin) + var(--caption-padding)*/);
        --radius: calc(var(--height-slide) * 6);
    }

    box-sizing: border-box;
    overflow: hidden;
    
    * {
        box-sizing: border-box;
    }
}

.slider-circular {
    user-select: none;
    padding: var(--padding-block-vertical) 0;
    transform-origin: 50% var(--radius);

    cursor: grab;    
    &:active {
        cursor: grabbing;
    }

    > .holder {
        position: relative;
        top:0;
        left:0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
    }
}

.slider-circular__item:empty {
    width: var(--width-slide-empty);
    flex: 0 0 var(--width-slide-empty);
}

.slider-circular__item {
    will-change: auto; // De serie es will-transform, pero aqui necesitamos que no tenga instancia ninguna. Position: static

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: transparent;
    border: 0;
    flex: 0 0 var(--width-slide);
    width: var(--width-slide);
    height: var(--height-slide);
    margin: 0;
        
    > .image {
        width: var(--width-slide);
        position: absolute;
        top: 0;
        left: calc(50% - var(--width-slide) * .5);
        transform-origin: 50% var(--radius);
        perspective: 100px;
        
        figure {
            position: absolute;
            left:0;
            top: 0;
            width: var(--width-slide);
            padding-top: 130%;
            margin: 0;
            transform-origin: 50% 100%;
            transform-style: preserve-3d;
        }

        figure img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: var(--height-image);
            object-fit: cover;
            object-position: 50% 20%;
            transform-origin: center;
        }
    }
  
    .caption {
        font-family: "Roobert-Medium";
        font-weight: normal;
        font-size: var(--caption-font-size);
        line-height: 1;
        color: white;
        text-transform: uppercase;

        
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: calc(100% + var(--caption-margin));
        left: 0;
        width: 100%;
        
        padding-top: var(--caption-padding);
        border-top: 1px solid white;
        
        > span:nth-child(2) {
            text-align: right;
        }

        @media (max-width:480px) {
            flex-direction: column;
            border-top: 1px solid rgba(255,255,255,.8);

            > span {
                display: block;
                text-align: left;
            }
            > span:nth-child(2) {
                border-top: 1px solid rgba(255,255,255,.8);
                text-align: left;
                padding-top: var(--caption-padding);
                margin-top: var(--caption-padding);
            }
        }
    }
}