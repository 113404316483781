.book-now {
    --padding-top-top: var(--padding-xxl);
    --padding-top-bottom: var(--padding-xl);
    --padding-h: var(--padding-l);
    --padding-v: var(--padding-xl);
    --font-size: var(--font-size-base);
    --font-size-text: var(--font-size-small);
    --icon-size: #{rem(32)};
    --font-size-serif: var(--font-size-xxxxxxxxxxxlarge);
    --font-size-sans: var(--font-size-xxxxxxxxxlarge);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --font-size-text: var(--font-size-xxsmall);
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-s);
        --padding-top-bottom: var(--padding-l);
    }

    @media (min-height: 920px) {
        --padding-top-top: var(--padding-xxxl);
    }
}

.book-now {
    @include z-index($z-index-windows);
    @include font-sans();  
    font-size: var(--font-size);
    background: var(--white);
    overflow: hidden;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    color: var(--green);
    
    button.--active {
        pointer-events: none;
    }

    @media (max-width: $smartphone) {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }
    
    > div[scroll-book-item] {
        min-height: 100vh;
        width: 100vw;
    }

    &__close {
        position: absolute;
        top: var(--padding-xxs);
        right: var(--padding-xs);
    }

    .tab {
        &:not(.--active) {
            height: 0;
            overflow: hidden;
        }
    }

    .top {
        padding: var(--padding-top-top) var(--padding-h) var(--padding-top-bottom);

        align-items: center;
        display: flex;
        justify-content: center;
        gap: var(--padding-s);
    }

    .text {
        font-size: var(--font-size-text);
    }

    .left {
        .text {
            margin-bottom: var(--padding-m);
            text-align: center;

            @include font-sans-medium();
            
            strong {
                @include font-sans-bold();
            }
        }

        .benefits {
            .icon {
                background-color: var(--white);
                border-radius: 50%;
                margin-right: var(--padding-xs);
                padding: var(--padding-xxxs);
                height: rem(48);
                width: rem(48);
                
                svg {
                    height: rem(32);
                    width: rem(32);
                    fill: var(--green);
                }
            }
     
            a {
                @include basic-a();
                color: var(--black);
                text-decoration: underline;
            }
        }
    }
        
    @media (max-width: $smartphone) {
        background-color: var(--lighter-green);
        height: 100%;

        .tab {
            display: flex;
            flex-direction: column;

            > * {
                width: 100%;
            }

            .right {
                order: 2;
                position: relative;

                // .mi-be-modal {
                //     position: absolute;

                //     .mi-be-modal-header {
                //         padding-left: rem(15) !important;
                //     } 

                //     .mi-be-modal-close {
                //         right: rem(15);
                //         left: auto !important;
                //     }
                // }
            }

            .center {
                order: 3;
            }
        }

        .top {
            background-color: var(--white);
            gap: var(--padding-xxxs);

            .button-kora {
                --font-size: var(--font-size-xxxxsmall);
                --width-open: 136px;

                .circle-holder {
                    display: none;
                }

                .text {
                    padding-left: var(--text-padding-right);
                }
            }
        }
        
        .bottom {
            .left {
                padding: 0 var(--padding-h) var(--padding-h);
                order: 3;
            }
            
            .right {
                background-color: var(--white);
                order: 1;
            }

            .center {
                order: 2
            }
            
            .right,
            .center {
                padding: var(--padding-h);
            }
        }

        .benefits {
            li {
                display: flex;
                margin-bottom: var(--padding-m);
            }
        }

        .title-1,
        .title-2 {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        .title-1,
        .title-2 {
            @include font-sans-display(1);
            font-size: var(--font-size-sans);
            text-transform: uppercase;
            position: absolute;
            color: var(--green15);

            > p {
                display: flex;
            }

            span {
                margin-top: rem(-10);
            }

            strong {
                @include font-serif(.73);
                font-size: var(--font-size-serif);
            }
        }

        .title-1 {
            top: rem(-32);
            left: rem(10);
        }

        .title-2 {
            bottom: rem(-35);
            right: 0;
        }

        .overlay {
            background: linear-gradient(90deg, var(--green15) 45%, var(--white) 45%);
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }

        > div[scroll-book-item] {
            position: relative;
        }

        .top {
            padding-left: 45%;
        }

        .tab {
            display: flex;

            .left {
                flex: 0 0 45%;    
                padding: 0 var(--padding-xl) var(--padding-l);

                .text {
                    background-color: var(--white);
                    border-radius: rem(55);
                    margin-top: -3em;
                    margin-bottom: var(--padding-l);
                    padding: var(--padding-s) var(--padding-m);
                    text-align: center;
                }

                .benefits {
                    max-width: rem(320);

                    li {
                        align-items: center;
                        display: flex;
                    }

                    li:not(:last-child) {
                        margin-bottom: var(--padding-m);
                    }

                    .icon {
                        padding: var(--padding-xs);
                        height: rem(64);
                        width: rem(64);

                        svg {
                            fill: var(--green);
                            height: rem(32);
                            width: rem(32);
                        }
                    }
                }
            }

            .center {
                padding-left: var(--padding-l);
                margin-right: var(--padding-m);
                flex: 0 0 rem(330);
            }

            .right {
                flex-grow: 1;
                padding-bottom: var(--padding-l);
                padding-right: var(--padding-l);

                .form,
                .form-vendor {
                    flex: 0 0 rem(340);
                    max-width: rem(340);
                }
            }
        }
    }
}

// CSS FROM MIRAI
@media (max-width: $smartphone) {
    body.mim-modal-open .book-now {
        overflow: unset;
    }
    body.mim-modal-open #Header>button {
        display: none;
    }
}
