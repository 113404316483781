@use "sass:math";

.block-two-ways {
    --color: var(--white);
    --font-size: var(--font-size-base);
    --font-size-title: #{rem(97)};
    --padding-v: var(--header-height);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-small);
        --font-size-title: var(--font-size-xxlarge);
    }
}

.block-two-ways {
    color: var(--color);

    @media (min-width: $smartphone) {
        display: flex;
        width: 100vw;
        height: 100vh;

        > div {
            flex: 0 0 50%;
        }
    }
}

.block-two-ways__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: var(--padding-v) var(--padding-h);
    
    .title-default {
        position: relative;
        --font-size: var(--font-size-title);
        margin-top: var(--font-size-title);
        margin-bottom: calc(var(--font-size-title) * .5);
    }

    // @include isCursor () {
    //     &:hover > figure::after {
    //         background-color: rgba($color: #000000, $alpha: 0);
    //     }
    // }
    
    > figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        // &::after {
        //     content: '';
        //     position: absolute;
        //     top:0;
        //     left:0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: rgba($color: #000000, $alpha: .4);
        //     transition: background-color .3s ease-out;
        // }

        img,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 110%;
            object-fit: cover;
            object-position: center center;
        }
    }

    > .text {
        @include font-sans-bold();
        position: absolute;
        top: var(--padding-v);
        left: 0;
        width: 100%;
        color: var(--color);
        text-align: center;
        opacity: 0;
    }

    .button-kora.--hollow {
        --border: var(--light-green);
    }
}
