@mixin type-post-9 {
    --font-size-title: var(--font-size-xxxlarge);

    .mask svg path {
        fill: var(--light-green);
    }

    .content {
        color: var(--dark-green);
    }

    .categories, 
    .time {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }
    
    .time {
        bottom: var(--padding-m);
    }
    
    .categories {
        top: var(--padding-m);
    }

    .title {
        color: var(--white);
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}

.new.--type-post-9 {
    @include type-post-9;
}
