
.mi-clubarea .mi-form {
    --form-space: #{rem(11)};
    --form-input-space: .5em;
    --corporative-text-color: var(--dark-green);
    margin-top: 1rem;

    > small {
        display: none;
    }


    .mi-club-signup__title {
        @include font-serif(1);

        font-weight: 800;
        color: var(--primary-color);

        p {
            @include font-sans();
        }
    }

    hr {
        display: none;
    }

    .mi-form-item__title {
        @include font-sans();
        font-weight: 800;
        padding-left: 1em;
        margin-bottom: .6em;
        text-transform: uppercase;
    }

    .mi-form-item__error {
        @include font-sans();
        padding-left: 1em;
    }

    .mi-form-item__select,
    .mi-form-item__date,
    .mi-form-item__input {
        border-color: var(--primary-brand);
        color: var(--primary-brand);
        border-radius: 2em;
    }

    .mi-clubarea-tabinfo__delete-button,
    .mi-form__submit {
        text-transform: uppercase;
        border-radius: 2em;
    }

    .mi-form-item:not(.mi-form-checkbox) {
        label {
            display: flex;
            align-items: center;

            > span {
                flex: 0 0 20%;
                font-size: rem(12);

                @media (max-width: $smartphone) {
                    flex: 0 0 30%;
                    font-size: 10px;
                }
            }

            .mi-form-item__password-wrapper {
                flex: 0 0 80%;

                @media (max-width: $smartphone) {
                    flex: 0 0 70%;
                    font-size: rem(12);
                }
            }

            @media (max-width: $smartphone) {
                select,
                input {
                    font-size: rem(12);
                }
            }
        }                
    }
}