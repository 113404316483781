.header-section-default {
    --color: var(--primary-color);
    --font-size: var(--font-size-base);
    --max-width: #{rem(372)};
    --padding-h: var(--padding-xl);

    @media (max-width: $smartphone) {
        --padding-h: var(--padding-xs);
    }
}

.header-section-default {
    font-size: var(--font-size-serif);
    padding: 0 var(--padding-h);

    > .text {
        @include font-sans();
        font-size: var(--font-size);
        max-width: var(--max-width);
    }
}