@use "sass:math";

.billboard-new {
    --font-size-title: var(--font-size-xxxxxxxlarge);
    --font-size-subtitle: var(--font-size-large);
    --font-size: var(--font-size-medium);
    --font-size-btn: var(--font-size-small);
    --border-radius: #{math.div(450px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-xxxlarge);
        --font-size-subtitle: var(--font-size-base);
        --font-size: var(--font-size-base);
        --font-size-btn: var(--font-size-xxsmall);
        --border-radius: #{math.div(450px, 16px) * 1rem};
        background-color: var(--dark-green);
    }
}

.new-blocks {
    --primary-color: var(--black);
    color: var(--primary-color);
}

.billboard-new {
    padding-bottom: var(--padding-l);

    .title-default {
        @include z-index(1);
        @include font-serif(.836);
        color: var(--primary-color);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-s);
        margin-top: calc(-1 * var(--padding-xxl));
        padding: 0 var(--padding-s);
        position: relative;
        text-transform: uppercase;
    }
    
    .bg {
        @include z-index(0);
        overflow: hidden;
        background-color: var(--dark-green);
        
        img {
            height: 100%;
            left: 0;
            object-fit: cover;
            position: absolute;
            top: 0;
            width: 100%;
            border-top-right-radius: var(--border-radius);
        }
    }
    
    .content {
        @include font-sans();
        position: relative;
        padding: 0 var(--padding-s);
        
        .share {
            font-size: var(--font-size-btn);
            background-color: transparent;
            color: var(--primary-color);
            padding: 0;
            text-decoration: underline;
        }
        
        .description {
            font-size: var(--font-size-subtitle);
            color: var(--primary-color);
            text-transform: uppercase;
        }
    }
        
    @media (max-width: $smartphone) {
        .bg {
            @include aspect-ratio(390, 410);
        }

        .content {
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;
    
            .share {
                margin-right: var(--padding-l);
            }

            .description {
                flex: 1;
                margin-bottom: 0;
            }
    
            .support-text {
                flex: 0 0 100%;
            }
        }
    }

    @media (min-width: $smartphone) {
        padding-bottom: var(--padding-xxl);

        .bg {
            height: rem(900);
            max-height: 100vh;
        }
            
        .title-default {
            margin-top: calc(-2 * var(--padding-xxxl));
            padding: 0 var(--padding-xxl);
        }
        
        .content {
            .share {
                position: absolute;
                right: rem(170);
            }

            .description {
                margin: 0 auto 0;
                max-width: rem(720);
            }

            .support-text {
                margin: 0 auto;
                max-width: rem(540);
            }
        }
    }
}
