.widget-locations-slider {
    --mod-size: min(1, var(--aspect-ratio) + .3);
    --width: 100vw;
    --height: 100vh;
    --height-visor: 100%;
    --width-visor: 50vw;
    --border-radius: var(--width-visor);
    --width-content: 50vw;
    --font-size-title: calc(var(--font-size-xxxxxxxxlarge) * var(--mod-size));
    --font-size-location: calc(var(--font-size-xsmall) * var(--mod-size));
    --font-size-text: calc(var(--font-size-base) * var(--mod-size));
    --font-size-claim: calc(var(--font-size-small) * var(--mod-size));
    --font-size-icons: calc(var(--font-size-xxxsmall) * var(--mod-size));
    --icon-size: calc(#{rem(30)} * var(--mod-size));
    --gap-controls: calc(var(--padding-xxs) * var(--mod-size));
    --padding-content: calc(var(--padding-xl) * var(--mod-size));
    --padding-controls: calc(var(--padding-m) * var(--mod-size));
    --padding-h: 0;
}

.widget-locations-slider {
    position: relative;
    width: var(--width);
    height: var(--height);
    margin-top: var(--padding-l);
    margin-bottom: var(--padding-xl);
    padding: 0 var(--padding-h);
}

.widget-locations-slider > ul {
    list-style: none;
    cursor: grab; 
  
    &:active {
        cursor: grabbing;
    }
}

.widget-locations-slider__item {
    width: var(--width-content);
    padding: var(--header-height) var(--padding-content) 0;

    position: absolute;
    top: 0;
    left: 0;

    &[aria-hidden='true'] {
        pointer-events: none;
    }

    > .location {
        --offset: 2em;
        position: relative;
        display: inline-block;
        @include font-sans();
        font-size: var(--font-size-location);
        text-transform: uppercase;
        color: currentColor;
        letter-spacing: .3em;
        color: var(--secondary-color);
        
        .icon {
            position: absolute;
            bottom: -.1em;
            left: 0;
            width: 1.5em;
            height: 1.5em;
            fill: currentColor;
        }
    }

    > .title {
        --font-size: var(--font-size-title);
        width: calc(var(--width) - var(--padding-content));
        margin: .1em 0 -.1em;
    }

    > .claim {
        @include font-sans-bold();
        font-size: var(--font-size-text);
        margin: 1.6em 0 1em;
        color: var(--secondary-color);
    }

    > .description {
        @include font-sans();
        font-size: var(--font-size-text);
        text-indent: 2em;
    }

    > .icons {
        list-style: none;
        display: flex;
        align-items: flex-start;
        gap: #{rem(10)};
        color: var(--secondary-color);

        > li {
            position: relative;
            flex: 0 0 25%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding-left: calc(var(--icon-size) + 1em);

            @include font-sans();
            font-size: var(--font-size-icons);

            transform-origin: center center;

            .icon {
                position: absolute;
                width: var(--icon-size);
                height: var(--icon-size);
                left: 0;
                top: calc((var(--icon-size) - 1em) * -.5);
                fill: currentColor;
            }
        }
    }

    @media (min-width: $smartphone) {
        height: var(--height-visor);
        bottom: 0;

        > .icons {
            margin-bottom: var(--padding-m);
        }
    }
}

.widget-locations-slider__visor {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    width: var(--width-visor);
    height: var(--height-visor);
    overflow: hidden;
    transform-origin: 50% 50%;
    position: relative;

    > .holder {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: 50% 50%;

        figure {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-top-left-radius: var(--border-radius);
            border-top-right-radius: var(--border-radius);
            transform-origin: 50% 100%;

            > img,
            > video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center bottom;
                transform-origin: 50% 50%;
            }
        }

        figure.media-holder {
            padding-top: 0;
        }
    }

    @media (min-width: $smartphone) {
        position: absolute;
        right: 0; 
        bottom: 0;
    }
}
