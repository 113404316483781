@use "sass:math";

#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary-color);
    // --bg: var(--primary-color);
    --padding-v: var(--padding-xxs);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-xxsmall);
    --gap: #{rem(40px)};

    --display-btn-toogle: none;
    --display-nav: flex;

    @media (max-width: $smartphone) {
        --display-btn-toogle: block;
        --display-nav: none;
    }
}

#Header .header__book-button {
    position: fixed;
    @include z-index($z-index-book-button);
    top: var(--padding-v);
    right: var(--padding-h);
}

#Header .header__toggle-button {
    position: fixed;
    @include z-index($z-index-book-button);
    top: var(--padding-v);
    left: var(--padding-h);

    opacity: 0;
    pointer-events: none;

    @media (max-width: $smartphone) {
        opacity: 1;
        pointer-events: all;
    }
}

#Header .header__bar {
    @include z-index($z-index-header);
    display: var(--display-btn-toogle);
    position: fixed;
    top: 0;
    left: 0;
    width: var(--width);
    height: var(--height);
    padding: var(--padding-v) var(--padding-h);

    display: var(--display-nav);
    justify-content: flex-start;
    align-items: center;
    gap: var(--gap);

    @include font-sans();
    font-size: var(--font-size);
    text-transform: uppercase;
    color: var(--color);
    background-color: var(--bg);

    transform: translate3d(0, var(--y-header), 10px);

    .logo {
        display: block;
        height: var(--logo-height);

        svg {
            height: 100%;
            width: auto;
            fill: var(--color);
        }
    }

    > a {
        color: inherit;
    }

    .toggle-menu {
        @include font-sans(1.2);

        font-size: var(--font-size);
        color: inherit;
        background-color: transparent;
        padding: 0;
        position: relative;
    }
}

#Header nav {
    display: flex;

    > button,
    > a {
        margin-left: 0 var(--padding-m);
        background: transparent;
        padding: 0;
        text-transform: uppercase;
    }
}

@media (max-width: $smartphone) {
  #Header {
      --font-size: #{math.div(16px, 16px) * 1rem};

      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;

      > .title {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
      }

      > .date {
          grid-column: 1 / 2;
          grid-row: 2 / 3;

          align-self: flex-end;
      }
  }

  #Header > nav {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      grid-template-columns: repeat(1, 1fr);

      text-align: right;

      a:not(:last-of-type) {
          margin-bottom: .4em;
      }
  }
}


.header__content__langs {
    --height-over: calc(var(--height) * 2);
    --height-normal: var(--height);
    --height-active: var(--height-normal);

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--width-lang);
    height: var(--height);
   
    cursor: pointer;
    

    .holder {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: var(--height-active);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        background-color: var(--color);
        border-radius:  calc(var(--height) * .5);

        transition: height .4s var(--ease-in-out-cubic);

        &:after {
            content: '↓';
            @include font-sans();
            position: absolute;
            height: var(--height);
            bottom: 0;
            right: var(--padding-xxs);
            color: var(--assertive);
            font-size: var(--font-size);
            line-height: var(--height);
        }
    }

    &:hover {
        --height-active: var(--height-over);
    }

    a {
        @include basic-a();
        @include font-sans();
        width: 100%;
        font-size: var(--font-size);
        color: var(--assertive);
        height: var(--height);
        line-height: var(--height);
        padding: 0 var(--padding-xxs);

        &.is-active {
            order: 1;
            pointer-events: none;
        }

        &:not(.is-active) {
            order: 2;
        }
    }
}