@use "sass:math";

.block-about {
    --font-title-serif: var(--font-size-xxxxxxxxxxlarge);
    --font-title-sans: var(--font-size-xxxxxxxxxlarge);
    --font-text-1-serif: var(--font-size-xl-2);
    --font-text-1-sans: var(--font-size-large);
    --font-text-2: var(--font-size-medium);
    --font-text-4-serif: var(--font-size-xxxxxlarge);
    --font-text-4-sans: var(--font-size-xxxxlarge);
    --padding-h: var(--padding-xl);
    --padding-v: var(--padding-xxl);
    
    @media (max-width: $smartphone) {
        --font-title-serif: var(--font-size-xxxlarge-2);
        --font-title-sans: var(--font-size-xxxlarge);
        --font-text-1-serif: var(--font-size-medium);
        --font-text-1-sans: var(--font-size-base);
        --font-text-2: var(--font-size-small);
        --font-text-4-serif: var(--font-size-xl-2);
        --font-text-4-sans: var(--font-size-xl);
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-xl);
    }
}

.block-about {
    color: var(--primary-color);
    @include font-sans();

    > div {
        color: var(--primary-color);
        // background-color: var(--primary-bg);
    }

    &__header {
        > [data-scroll-sticky] {
            padding: var(--padding-v) var(--padding-h);
        }

        .image {
            width: rem(225);
        }
    }

    &__multiple {
        padding: 0 var(--padding-h);
    }

    &__text {
        padding: var(--padding-v) var(--padding-h);
        text-transform: uppercase;
    }

    .text-1 {
        @include font-sans-display();
        font-size: var(--font-text-1-sans);
        text-align: center;
        
        b,
        strong {
            @include font-serif(1.26);
            font-size: var(--font-text-1-serif);
            text-transform: uppercase;
        }
    }

    .text-2,
    .text-3 {
        @include font-sans();
        font-size: var(--font-text-2);
    }

    .text-4 {
        // @include font-sans-display();
        // font-size: var(--font-text-4-serif);
        // text-align: center;
        // margin: auto;
        
        // b,
        // strong {
        //     @include font-serif();
        //     font-size: var(--font-text-4-sans);
        // }
        --font-size: var(--font-text-4-serif);
    }

    .image-2 {
        video,
        img {
            object-fit: cover;
        }
    }
 
    @media (max-width: $smartphone) {
        .dsk { display: none; }

        &__header {
            > [data-scroll-sticky] {
                padding-bottom: 0;
            }

            .image {
                margin: var(--padding-xl) auto 0;
            }
        }
        
        &__multiple {
            padding-bottom: 0;
            padding-top: 0;

            .image-2 {
                margin: var(--padding-s) 0;
            }

            .text-2,
            .text-3 {
                width: 100%;
            }

            .text-3 {
                margin-left: 0;
            }
        }

        .block-about__quote {
            margin-top: 0;
        }
    }

    @media (min-width: $smartphone) {
        .mob { display: none; }

        &__header {
            height: 800vh;

            > [data-scroll-sticky] {
                align-items: center;
                display: flex;
                justify-content: center;
                height: 100vh;
            }

            .image {
                position: absolute;
                width: rem(600);
            }
        }

        .text-1 {
            margin: auto;
            max-width: rem(805);
        }

        &__multiple {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            

            > * {
                min-width: rem(351);
                width: rem(351);
            }

            .text-2 {
                margin-top: rem(150);
            }

            .text-3 {
                margin-top: rem(350);
            }
        }  
        
        .text-4 {
            margin: auto;
            max-width: rem(800);
        }

        .block-about__quote {
            margin-top: 10vw;
        }
    }
}

