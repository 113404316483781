.block-billboard-home {
    --text-max-width: #{rem(320)};
    --window-width: #{rem(280)};
    --window-height: #{rem(360)};

    align-items: center;
    display: flex;
    position: relative;
    width: 100vw;
    
    > * {
        opacity: 0;
    }

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img,
        video {
            height: auto;
            width: 100%;
        }
    }

    .window {
        height: var(--window-height);
        position: relative;
        min-width: var(--window-width);
        width: var(--window-width);
    }

    .window {
        pointer-events: none;

        figure {
            height: 100%;
            width: 100%;
        }

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100vh;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        &.--column {
            gap: 0;
            flex-direction: column;
        }
    }

    .button-kora {
        &.--hollow {
            --bg: transparent;
            --bg-circle: transparent;
            --border: var(--light-green);
            --fill: var(--border);
            --border-circle: var(--border);
            --color: var(--border);
        }

        @include z-index(1);
    
        position: absolute;
        top: 73vh;
        top: calc(100% - #{rem(60)});
        left: 50%;
        transform: translate3d(-50%, 0, #{1}px);
        opacity: 1;
    }

    .text {
        width: var(--text-max-width);
    }

    @media (min-width: $smartphone) {
        --text-max-width: #{rem(940px)};
        --window-width: #{rem(351)};
        --window-height: #{rem(450)};

        height: 100vh;

        .wrapper {
            gap: rem(20);
            
            .window {
                &.--1 {
                    @include z-index(3);
                }

                &.--2 {
                    @include z-index(5);
                }

                &.--3 {
                    @include z-index(1);
                }
            }
        }

        .holder {
            align-items: center;
            display: flex;
            justify-content: center;
            height: 100vh;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;

            gap: rem(20);
        }

        .button-kora {
            top: calc(100vh - #{rem(100)});
        }
    }

    @media (min-width: $xlargeScreen) {
        --text-max-width: #{rem(845px)};
        --window-width: #{rem(316)};
        --window-height: #{rem(405)};
    }
}
