
@use "sass:math";

.block-slider-cards-3D {
    --mod-size: min(1, var(--aspect-ratio) + .1);
    --width: calc((100vw - var(--padding-xl) * 2) * var(--mod-size));
    --padding-v: 50vw;//var(--padding-xl);
    --padding-h: calc((100vw - var(--width))/2);
}

.block-slider-cards-3D {
    position: relative;
    padding: var(--padding-v) var(--padding-h) 0;

    > .slider-cards-3D {    
        @include aspect-ratio(1280, 520);

        @media (max-width: $smartphone) {
            @include aspect-ratio(190, 320);
        }
    }
}

////////////////////
/////SLIDER/////////
////////////////////
.slider-cards-3D {
    --font-size-text: calc(var(--font-size-medium-2) * var(--mod-size));
    --font-size-serif: calc(#{rem(138)} * var(--mod-size));
    --font-size-sans: calc(var(--font-size-serif) * 0.24);
    --margin-top-title: -.4em;
    --margin-title-serif: -.18em;
    --aspect-ratio: .4;
    --width-slide: 100%;
    --height-slide: 100%;
    --radius: 1rem;
        
    @media (max-width: 480px) {
        --margin-top-title: 0;
        --font-size-serif: #{rem(48)};
        --font-size-text: var(--font-size-small);
        --width-slide: 100%;
        --height-slide: 100%;
        --radius: calc(var(--height-slide) * 6);
    }

    box-sizing: border-box;
    * {
        box-sizing: border-box;
    }
}

.slider-cards-3D {
    user-select: none;
    padding: var(--padding-block-vertical) 0;
    
    cursor: grab;    
    &:active {
        cursor: grabbing;
    }

    > .holder {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        perspective: 10vw;
    }
}

.slider-cards-3D__item:empty {
    width: var(--width-slide-empty);
    flex: 0 0 var(--width-slide-empty);
}

.slider-cards-3D__item {
    will-change: auto; // De serie es will-transform, pero aqui necesitamos que no tenga instancia ninguna. Position: static

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    background: transparent;
    border: 0;
    flex: 0 0 100%;
    width: 100%;
    margin: 0;
}

.slider-cards-3D__card {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: var(--width-slide);
    height: var(--height-slide);
    background-color: white;
    border-radius: 1rem;
    overflow: hidden;
    padding: var(--padding-xs) var(--padding-s);

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    transform-style: preserve-3d;
    transform-origin: top right;

    @media (max-width: $smartphone) {
        display: block;
        transform-origin: top left;
    }

    > .tapa {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-bg);

        @media (max-width: $smartphone) {
            display: none;
        }
    }

    > figure {
        position: absolute;
        top: 0;
        right: 0;
        width: 40%;
        height: 100%;

        @media (max-width: $smartphone) {
            top: auto;
            bottom: 0;
            width: 100%;
            height: 30%;
        }

        img {
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
            user-drag: none;

            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .title {
        text-transform: uppercase;
        flex: 1 0 100%;
        @include font-serif(.7);
        font-size: var(--font-size-serif);        
        margin-top: var(--margin-top-title);

        strong {
            @include font-sans(.7);
            line-height: var(--font-size-sans);
            font-size: var(--font-size-sans);
            margin-top: var(--margin-title-serif);
            margin-bottom: -5.2em;
        }
    }

    .text {
        @include font-sans();
        font-size: var(--font-size-text);
        flex: 0 0 50%;
        margin: 0;

        > p:not(:first-child) {
            margin-top: 1em;
        }

        > p:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}

@media (max-width: 480px) {
    .block-slider-cards-3D > .controls {
        padding-top: var(--padding-xs);
    }

    .slider-cards-3D__card {
        padding: var(--padding-xs);

        .title {
            margin-bottom: var(--padding-xs);
        }
    }
}
