
@use "sass:math";

.block-blockquote {
    color: var(--primary-color);
    margin-top: 50vh;
    padding: 40vh 15vw var(--padding-xxl);   

    > .title-default {
        --font-size: #{rem(62px)};
        
        text-transform: none;
    }

    @media (max-width: $smartphone) {
        margin-top: 5vh;
        padding: var(--padding-xl) var(--padding-s);

        > .title-default {
            --font-size: var(--font-size-xl);
        }
    }

    @media (max-height: $smartphone) {
        margin-top: 10vh;
        padding: var(--padding-xl) var(--padding-s);

        > .title-default {
            --font-size: var(--font-size-xl);
        }
    }
}
