.blog .news-list {
    --padding-top: calc(var(--header-height) + var(--padding-xxl));
    
    .title-default {
        > span:first-child {
            @include font-serif();
            line-height: var(--font-size-sans);
            font-size: var(--font-size-serif);
            margin-top: var(--margin-serif);
        }
    }

    @media (max-width: $smartphone) {
        --pading-top: calc(var(--header-height) + var(--padding-m));
    }
}