@import "news-type/type-post-1";
@import "news-type/type-post-2";
@import "news-type/type-post-3";
@import "news-type/type-post-4";
@import "news-type/type-post-5";
@import "news-type/type-post-6";
@import "news-type/type-post-7";
@import "news-type/type-post-8";
@import "news-type/type-post-9";
@import "news-type/type-post-10";

.new {
    --font-size-categ: var(--font-size-xsmall);
    --scale: 1;

    &:hover {
        --scale: 1.2;
    }
}

.new {
    @include aspect-ratio(451, 562);

    border-radius: rem(16);
    overflow: hidden;
    position: relative;
    text-align: center;

    .mask,
    .content,
    .thumbnail {
        bottom: 0;
        top: 0;
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
    }

    .mask {
        svg {
            height: 100%;
            width: 100%;
        }
    }

    .thumbnail {
        img {
            height: 100%;
            object-fit: cover;
            transform: scale3d(var(--scale), var(--scale), var(--scale));
            transform-origin: center center;
            transition: transform .6s var(--ease-out-quad);
            width: 100%;
            border-radius: rem(16);
        }
    }

    .categories, 
    .time,
    .title {
        position: absolute;
    }

    .categories, 
    .time {
        @include font-sans();
        font-size: var(--font-size-categ);
    }

    .time {
        display: none;
    }
    
    .title {
        @include font-serif();
        font-size: var(--font-size-title);
        line-height: .93;
        text-transform: uppercase;
        width: 100%;

        span {
            margin-right: .25em;
        }

        span:last-child {
            margin-right: 0;
        }
    }
}
