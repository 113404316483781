@use "sass:math";

.block-quote {
    --font-size: var(--font-size-xl);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-s);
    --max-width: #{math.div(1000px, 16px) * 1rem};
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-m);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
    }
}

.block-quote {
    @include font-sans(1);
    
    color: inherit;
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    
    > * {
        margin: 0 auto;
        max-width: var(--max-width);
        text-align: center;
    }
}
