@use "sass:math";

$marginSans: .15em;
$marginSerif: .24em;
$padding: .12em;

.block-parallax-cards {
    --padding-h: var(--padding-xl);
    --padding-v: calc(var(--header-height) + var(--padding-xxl));
    
    .title-default {
        --margin-sans: #{-1 * ($padding * 2 + $marginSans)};
        --margin-serif: #{-1 * ($padding * 2 + $marginSerif)};
        --font-size: var(--font-size-xlarge);
    }
    
    @media (max-width: $smartphone) {
        .title-default {
            --font-size: var(--font-size-large-2);
        }
    
        --padding-h: var(--padding-s);
        --padding-v: calc(var(--header-height) + var(--padding-m));
    }
}

.block-parallax-cards {
    @include font-sans();
    
    color: var(--primary-color);
    background-color: var(--primary-bg);
    font-size: var(--font-size);
    padding: 0 var(--padding-h);

    .title-default {
        text-transform: initial;
    }
    
    .text {
        p:not(:last-child) {
            margin-bottom: var(--padding-s);
        }
    }
 
    @media (max-width: $smartphone) {
        .title-default {
            margin-bottom: var(--padding-s);
        }

        > .wrapper {
            padding-top: var(--padding-v);
        }

        .items {
            position: relative;
            padding: var(--padding-l) 0;

            .parallax-card:not(:last-child) {
                margin-bottom: var(--padding-s);
            }
        }
    }
        
    @media (min-width: $smartphone) {
        .title-default {
            flex-grow: 1;
        }

        > .wrapper {
            align-items: center;
            display: flex;
            justify-content: space-between;
            gap: var(--padding-xl);
            height: 100vh;

            .text {
                max-width: rem(540);
            }
        }

        .items {
            position: relative;
            padding: 1px 0 50vh;
        }
    }
}
