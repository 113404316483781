@use "sass:math";

.controls {
    --padding: var(--padding-l) var(--padding-m);
    --justify-content: center;
    --align-items: flex-end;
    --gap: var(--padding-xxs);

    @media (max-width: $smartphone) {
        --padding: var(--padding-m) var(--padding-s);
        --gap: 4px;
    }
}

.controls {
    &:not(.--auto) {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        max-height: 100vh;
    }

    width: 100%;
    padding: var(--padding);
    pointer-events: none;

    display: flex;
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    gap: var(--gap);

    > * {
        pointer-events: all;
    }

    .--disabled {
        pointer-events: none;
    }
}